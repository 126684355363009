import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../actions/uiActions';
import { GeneralState } from '../../models/State';
import DBButton from '../DBButton/DBButton';
import DBModalHeader from './DBModalHeader';
import ModalTextField from './ModalTextField';
import { Tabs as TabsNames } from '../../models/Tabs';
import './DeleteModal.less';
import { getAllUsers, removeUser } from '../../actions/userActions';
import { getAllGroups, removeGroup } from '../../actions/groupActions';
import { useLocation } from 'react-router-dom';
import { removeRestriction } from '../../actions/restrictionsActions';
import { validateRequest } from '../../utils/validateRequest';
import {
  deleteApprovalGroup,
  getAllApprovalGroups,
} from '../../actions/approvalGroupActions';

const DeleteModal: React.FC = () => {
  const dispatch = useDispatch();
  const pathName = useLocation().pathname.substr(1);
  const { Groups, Participants, ApprovalGroups } = TabsNames;
  const { currentRow, currentTab } = useSelector<GeneralState, any>(
    (state) => state.ui,
  );

  const getNameFromCurrentRow = (rowInfo: any) => {
    return rowInfo.name
      ? rowInfo.name
      : `${rowInfo.firstName} ${rowInfo.lastName}`;
  };

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const handleDelete = () => {
    if (pathName !== 'restrictions')
      switch (currentTab) {
        case Groups:
          dispatch(removeGroup(currentRow.id));
          dispatch(closeModal());
          setTimeout(() => dispatch(getAllGroups()), 500);
          break;
        case Participants:
          dispatch(removeUser(currentRow.id));
          dispatch(closeModal());
          setTimeout(() => dispatch(getAllUsers()), 1500);
          break;
        case ApprovalGroups:
          dispatch(deleteApprovalGroup(currentRow.id));
          dispatch(closeModal());
          setTimeout(() => dispatch(getAllApprovalGroups()), 1500);
          break;
        default:
          return null;
      }
    else {
      dispatch(removeRestriction(currentRow.id));
      dispatch(closeModal());
      setTimeout(() => validateRequest(currentTab, dispatch), 500);
    }
  };

  return (
    <DBModalHeader
      title={`Delete ${
        pathName === 'restrictions' ? 'Restricition' : currentTab.slice(0, -1)
      }`}
      className="DeleteModal"
    >
      <ModalTextField
        description={
          <span>
            Are you sure you want to delete{' '}
            <strong>{getNameFromCurrentRow(currentRow)}</strong>?
          </span>
        }
      />
      <div className="buttonsContainer">
        <DBButton variety="delete" htmlType="submit" onClick={handleDelete}>
          Delete
        </DBButton>
        <DBButton variety="cancel" onClick={handleCancel}>
          Cancel
        </DBButton>
      </div>
    </DBModalHeader>
  );
};

export default DeleteModal;
