import { GET_GROUPS, GET_GROUP, GROUPS_ERROR } from '../actions/types';

const initialState = {
  groups: [],
  group: null,
  filterValues: null,
  loading: true,
};

export const groupsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_GROUPS:
      return {
        ...state,
        groups: action.payload,
        filterValues: action.filterValues,
        loading: false,
      };
    case GET_GROUP:
      return {
        ...state,
        group: action.payload,
        loading: false,
      };
    case GROUPS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
