import React from 'react';
import { Layout, PageHeader } from 'antd';
import DBTab from '../DBTab/DBTab';
import { Tabs } from '../../models/Tabs';
import { useLocation } from 'react-router-dom';
import './DBDashboard.less';
import OverviewDashboard from './OverviewDashboard';
import { useDispatch, useSelector } from 'react-redux';
import {
  FileAddOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import DBButton from '../DBButton/DBButton';
import { openModal } from '../../actions/uiActions';
import { ModalType } from '../../models/Modal';
import { GeneralState } from '../../models/State';

const { Content } = Layout;

const DBDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { currentTab } = useSelector<GeneralState, { currentTab: string }>(
    (state) => state.ui,
  );

  const pathName = useLocation().pathname.substr(1);
  const {
    Transactions,
    PendingRequests,
    Participants,
    Groups,
    All,
    Active,
    Future,
    Ended,
    ApprovalGroups,
    Restrictions,
  } = Tabs;

  const getTabForCurrentDashboard = (pathName: string) => {
    switch (pathName) {
      case 'requests':
        return [Transactions, PendingRequests];
      case 'participants':
        return [Participants];
      case 'restrictions':
        return [All, Active, Future, Ended];
      case 'groups':
        return [Groups, ApprovalGroups];
      default:
        return null;
    }
  };

  const renderDashboard = () => {
    if (pathName === 'overview') {
      return <OverviewDashboard />;
    } else {
      return <DBTab tabs={getTabForCurrentDashboard(pathName)} />;
    }
  };

  const handleOpenModal = (type: string) => {
    switch (type) {
      case Participants:
        return dispatch(openModal(ModalType.AddParticipantModal));
      case Groups:
        return dispatch(openModal(ModalType.AddGroupModal));
      case ApprovalGroups:
        return dispatch(openModal(ModalType.AddApprovalGroup));
      case Restrictions:
        return dispatch(openModal(ModalType.AddRestrictionModal));
      default:
        return null;
    }
  };

  const RenderButton: React.FC = () => {
    const pathName = useLocation().pathname.substr(1);
    let participantsButtons = (
      <>
        <DBButton
          title="Add Participant"
          icon={<UserAddOutlined />}
          variety="cancel"
          key={pathName + 1}
          onClick={() => handleOpenModal(Participants)}
          style={{ width: '180px' }}
        >
          Add Participants
        </DBButton>
        <DBButton
          title="Bulk Add Participants"
          icon={<UserAddOutlined />}
          variety="cancel"
          key={pathName + 2}
          onClick={() => dispatch(openModal(ModalType.AddParticipantBulkModal))}
          style={{ width: '220px' }}
        >
          Bulk Add Participants
        </DBButton>
      </>
    );

    let restrictionButtons = (
      <DBButton
        title="Add Restriction"
        icon={<FileAddOutlined />}
        variety="cancel"
        key={pathName}
        onClick={() => handleOpenModal('Restrictions')}
      >
        Add Restriction
      </DBButton>
    );

    let groupButtons = (
      <DBButton
        title="Add Group"
        icon={<UsergroupAddOutlined />}
        variety="cancel"
        key={pathName}
        onClick={() => handleOpenModal(Groups)}
      >
        Add Group
      </DBButton>
    );

    let approvalGroupButtons = (
      <DBButton
        title="Add Group"
        icon={<UsergroupAddOutlined />}
        variety="cancel"
        key={pathName}
        onClick={() => handleOpenModal(ApprovalGroups)}
      >
        Add Approval Group
      </DBButton>
    );

    if (pathName !== 'restrictions') {
      switch (currentTab) {
        case Participants:
          return participantsButtons;
        case Groups:
          return groupButtons;
        case ApprovalGroups:
          return approvalGroupButtons;
        default:
          return null;
      }
    } else {
      return restrictionButtons;
    }
  };

  return (
    <Content style={{ margin: '24px 16px 0', position: 'relative' }}>
      <PageHeader
        className="PageHeader"
        title={pathName}
        extra={<RenderButton />}
      />
      {renderDashboard()}
    </Content>
  );
};

export default DBDashboard;
