import React, { useEffect } from 'react';
import DBButton from '../DBButton/DBButton';
import { Formik, Form, FormikProps } from 'formik';
import { Form as FormAntd } from 'antd';
import './AddParticipantModal.less';
import DBInputTextField from '../DBForm/DBInputTextField';
import { api } from '../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../actions/uiActions';
import DBSwitchField from '../DBForm/DBSwitchField';
import { addUser, getAllUsers } from '../../actions/userActions';
import { getAllApprovalGroups } from '../../actions/approvalGroupActions';
import { getAllGroups } from '../../actions/groupActions';
import { ApprovalGroupState, GroupState } from '../../models/State';
import { Group, User } from '../../api/backend-api';
import DBModalHeader from './DBModalHeader';
import DBSelect from '../DBFilters/DBSelect';

interface Values {
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  isManager: boolean;
  approvalGroups: string[];
  groups: string[];
}

const checkEmailExist = async (email: string) => {
  try {
    const res = await api.isEmailAvailable(email);
    return res ?? false;
  } catch (err) {
    console.log(err);
    return true;
  }
};

const AddParticipantModal: React.FC = () => {
  const dispatch = useDispatch();
  const { approvalGroups, approvalGroupsLoading } = useSelector<
    ApprovalGroupState,
    any
  >((state) => state.approvalGroups);
  const { groups, loading } = useSelector<GroupState, any>(
    (state) => state.groups,
  );

  const handleCancel = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    dispatch(getAllGroups());
    dispatch(getAllApprovalGroups());
  }, [dispatch]);

  return (
    <DBModalHeader title="Add Participant" className="AddParticipantModal">
      <Formik
        validateOnBlur={true}
        validateOnChange={false}
        validate={async (values) => {
          const errors: any = {};
          if (values.firstName.length <= 0) {
            errors.firstName = 'You must enter a first name';
          }
          if (values.lastName.length <= 0) {
            errors.lastName = 'You must enter a last name';
          }
          if (values.jobTitle.length <= 0) {
            errors.jobTitle = 'You must enter a job title';
          }
          if (values.email.length <= 0) {
            errors.email = 'You must enter an email';
          } else if (
            !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              values.email,
            )
          ) {
            errors.email = 'Email is not valid';
          } else if (
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              values.email,
            ) &&
            !(await checkEmailExist(values.email))
          ) {
            errors.email = 'Email is already in use';
          }

          return errors;
        }}
        initialValues={{
          firstName: '',
          lastName: '',
          jobTitle: '',
          email: '',
          isManager: false,
          approvalGroups: [],
          groups: [],
        }}
        onSubmit={(values, actions) => {
          dispatch(addUser({ ...values } as unknown as User));
          actions.setSubmitting(false);
          dispatch(closeModal());
          setTimeout(() => dispatch(getAllUsers()), 1500);
        }}
      >
        {(props: FormikProps<Values>) => {
          return (
            <Form className="ant-form ant-form-vertical">
              <DBSwitchField
                label="Create as manager"
                onChange={(value: boolean) =>
                  props.setFieldValue('isManager', value)
                }
              />
              <DBInputTextField
                name="firstName"
                type="text"
                label="First Name"
              />
              <DBInputTextField name="lastName" type="text" label="Last Name" />
              <DBInputTextField name="jobTitle" type="text" label="Job Title" />
              <DBInputTextField name="email" type="text" label="Email" />
              <FormAntd.Item label="Groups" className="SelectGroupField">
                <DBSelect
                  mode="multiple"
                  placeholder="Select groups"
                  options={groups.map((group: Group) => ({
                    key: group.id,
                    label: group.name,
                    value: group.name,
                  }))}
                  value={props.getFieldProps('groups').value}
                  theme="light"
                  dropdownClassName="DBSelectDropdownLight"
                  loading={loading}
                  onChange={(selected: string[]) =>
                    props.setFieldValue('groups', selected)
                  }
                />
              </FormAntd.Item>
              <FormAntd.Item
                label="Approval Group"
                className="SelectApprovalGroupField"
              >
                <DBSelect
                  placeholder="Select approval group"
                  options={approvalGroups.map((group: Group) => ({
                    key: group.id,
                    label: group.name,
                    value: group.name,
                  }))}
                  value={props.getFieldProps('approvalGroup').value}
                  allowClear={true}
                  theme="light"
                  dropdownClassName="DBSelectDropdownLight"
                  loading={approvalGroupsLoading}
                  onChange={(selected: string[]) =>
                    props.setFieldValue('approvalGroup', selected)
                  }
                />
              </FormAntd.Item>
              <div className="buttonsContainer">
                <DBButton
                  variety="create"
                  disabled={!(props.dirty && props.isValid)}
                  htmlType="submit"
                >
                  Create
                </DBButton>
                <DBButton variety="cancel" onClick={handleCancel}>
                  Cancel
                </DBButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </DBModalHeader>
  );
};

export default AddParticipantModal;
