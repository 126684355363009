import React, { useEffect } from 'react';
import DBButton from '../DBButton/DBButton';
import { Formik, Form, FormikProps } from 'formik';
import { Form as FormAntd } from 'antd';
import './EditParticipantModal.less';
import DBInputTextField from '../DBForm/DBInputTextField';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../actions/uiActions';
import { getAllUsers } from '../../actions/userActions';
import { GeneralState, UserState } from '../../models/State';
import DBModalHeader from './DBModalHeader';
import DBSelect from '../DBFilters/DBSelect';
import { ApprovalGroup, User } from '../../api/backend-api';
import {
  getAllApprovalGroups,
  updateApprovalGroup,
} from '../../actions/approvalGroupActions';
import { formatDate } from '../../utils/Utils';
import './EditApprovalGroupModal.less';

interface Values {
  name: string;
  createdOn: string | Date;
  approvalManagers: string[];
}

const EditApprovalGroupModal: React.FC = () => {
  const dispatch = useDispatch();
  const { users, loading } = useSelector<GeneralState, UserState>(
    (state) => state.users,
  );
  const { currentRow } = useSelector<GeneralState, any>((state) => state.ui);

  const handleCancel = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  return (
    <DBModalHeader
      title="Edit Approval Group"
      className="EditApprovalGroupModal"
    >
      <Formik
        validateOnBlur={true}
        validateOnChange={false}
        validate={async (values) => {
          const errors: any = {};
          if (values.name.length <= 0) {
            errors.name = 'You must enter a name';
          }
          return errors;
        }}
        initialValues={{
          name: currentRow.name,
          createdOn: currentRow.createdOn,
          approvalManagers: [
            ...currentRow.approvalManagers.map((manager: any) => manager.email),
          ],
        }}
        onSubmit={(values: Values, actions) => {
          dispatch(
            updateApprovalGroup(currentRow.id, {
              ...values,
              approvalManagers: values.approvalManagers.map((manager) => ({
                email: manager,
                fullName: users
                  .filter((user: User) => manager === user.email)
                  .map((user: any) => `${user.firstName} ${user.lastName}`)[0],
              })),
            } as unknown as ApprovalGroup),
          );
          actions.setSubmitting(false);
          dispatch(closeModal());
          setTimeout(() => dispatch(getAllApprovalGroups()), 1500);
          actions.setSubmitting(false);
        }}
      >
        {(props: FormikProps<Values>) => {
          return (
            <Form className="ant-form ant-form-vertical">
              <DBInputTextField
                name="name"
                type="text"
                label="Name"
                onChange={props.handleChange}
              />
              <DBInputTextField
                name="createdOn"
                type="text"
                label="Created On"
                value={formatDate(currentRow.createdOn, 'd MMM yyyy')}
                disabled={true}
              />
              <FormAntd.Item label="Managers" className="SelectGroupField">
                <DBSelect
                  mode="multiple"
                  placeholder="Select managers"
                  options={users
                    .filter((user) => user.isManager)
                    .map((user, index) => ({
                      key: user.id || index,
                      label: user.email,
                      value: user.email,
                    }))}
                  value={props.getFieldProps('approvalManagers').value}
                  theme="light"
                  dropdownClassName="DBSelectDropdownLight"
                  loading={loading}
                  onChange={(selected: string[]) =>
                    props.setFieldValue('approvalManagers', selected)
                  }
                />
              </FormAntd.Item>
              <div className="buttonsContainer">
                <DBButton
                  variety="create"
                  disabled={!(props.dirty && props.isValid)}
                  htmlType="submit"
                >
                  Save
                </DBButton>
                <DBButton variety="cancel" onClick={handleCancel}>
                  Cancel
                </DBButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </DBModalHeader>
  );
};

export default EditApprovalGroupModal;
