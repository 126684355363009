import csv from 'csv';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useImportAPI } from '../../../context/CSVContext';
import { useBoolean } from '../../../utils/useBoolean';
import Box from '../../Box/Box';
import DBButton from '../../DBButton/DBButton';
import DBTag from '../../DBTag/DBTag';
import Flex from '../../Flex/Flex';
import './CSVUpload.less';
import { api as restApi } from '../../../api/api';
import useCommonState from '../../../utils/useCommonState';

const CSVUpload = () => {
  const { csvData, setCsvData, csvString, setCsvString, ...api } =
    useImportAPI();
  const [fileName, setFileName] = useState('');

  const [isUploading, uploadingAccessor] = useBoolean();
  // const [headerCheckPassed, headerCheckAccessor] = useBoolean();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFileName(acceptedFiles[0].name);

    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading failed');
    reader.onload = () => {
      // Parse CSV file
      uploadingAccessor.off();
      if (reader.result) {
        // @ts-ignore, this method has no typings
        csv.parse(reader.result, (err, data) => {
          // console.log('Parsed CSV data: ', data);
          // console.log('err', err);
          setCsvData(data);
        });
        setCsvString(reader.result as string);
      }
    };

    // read file contents
    reader.readAsBinaryString(acceptedFiles[0]);

    // Do something with the files
    // console.log(acceptedFiles);
  }, []);

  const gridConversion = useMemo(() => {
    if (csvData) {
      return csvData.map((row) => row.map((cell) => ({ value: cell })));
    }
  }, [csvData]);

  const errorResults = useMemo(() => {
    if (gridConversion) {
      let errorResults = api.errorCheck(gridConversion);

      let filterOutEmpties = Object.values(errorResults).filter(
        (err) => err.length > 0,
      );
      return filterOutEmpties;
    }
  }, [gridConversion]);

  // memo that can be used as short-hand to conditionally check errors
  const hasErrors = useMemo(
    () => !!(errorResults && Object.values(errorResults).length > 0),
    [errorResults],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const { err: networkErr, setErr: setNetworkErr } = useCommonState();

  useEffect(() => {
    return () => {
      setCsvData([]);
      setNetworkErr();
    };
  }, []);

  const handleUpload = () => {
    console.log(csvData, csvString);
    csvString &&
      restApi
        .csvUploader(csvString)
        .then((res) => api.setTabIndex(0))
        .catch((err) => setNetworkErr(err));
  };

  return (
    <Box marginTop={12} width="100%" paddingBottom={24}>
      <div {...getRootProps()}>
        <Flex
          width="100%"
          height="100px"
          borderWidth="2px"
          borderStyle="dashed"
          borderColor={isDragActive ? '#62b0e8' : '#cbd2d9'}
          background={isDragActive ? '#def1ff' : 'none'}
          opacity={isDragActive ? 0.6 : 1}
          cursor="pointer"
          borderRadius="12px"
          color="var(--app-modal-label-text-color)"
          justifyContent="center"
          alignItems="center"
        >
          <input {...getInputProps()} />
          <svg
            viewBox="0 0 24 24"
            focusable="false"
            style={{ width: 16, marginRight: 4 }}
          >
            <path
              fill="currentColor"
              d="M21.843,3.455a6.961,6.961,0,0,0-9.846,0L1.619,13.832a5.128,5.128,0,0,0,7.252,7.252L17.3,12.653A3.293,3.293,0,1,0,12.646,8L7.457,13.184A1,1,0,1,0,8.871,14.6L14.06,9.409a1.294,1.294,0,0,1,1.829,1.83L7.457,19.67a3.128,3.128,0,0,1-4.424-4.424L13.411,4.869a4.962,4.962,0,1,1,7.018,7.018L12.646,19.67a1,1,0,1,0,1.414,1.414L21.843,13.3a6.96,6.96,0,0,0,0-9.846Z"
            ></path>
          </svg>
          <div>Click, or drag your own CSV here</div>
        </Flex>
      </div>

      {/* 
        @TODO: replace with new and improved csv template 
        Downloadable files are located in the /public folder
        */}
      <p className="link">
        Example templates: &nbsp;
        <a
          target="_blank"
          href="/example_template_with_grouping.csv"
          className="link"
        >
          Groups
        </a>
        ,&nbsp;
        <a
          target="_blank"
          href="/example_template_with_grouping_and_approval_groups.csv"
          className="link"
        >
          Groups And Approval Groups
        </a>
      </p>
      <Box>
        {fileName && (
          <Box margin="12px 0px">
            Currently reading
            <span className="badge">{fileName}</span>
          </Box>
        )}
        {isUploading && (
          <Box margin="12px 0px">
            Uploading...
            {/* <Spinner size="sm" ml={2} /> */}
          </Box>
        )}
        {fileName && !errorResults && (
          <Box margin="12px 0px">
            Checking for issues...
            {/* <Spinner size="sm" ml={2} /> */}
          </Box>
        )}
        {fileName && !networkErr && errorResults?.length === 0 && (
          <Box margin="12px 0px">
            <DBTag theme="approved">No issues found</DBTag>
          </Box>
        )}
        {hasErrors && (
          <Box margin="12px 0px">
            <DBTag theme="cancelled">
              Please fix the following issues before upload{' '}
            </DBTag>
          </Box>
        )}
        {networkErr && (
          <Box margin="12px 0px">
            <DBTag theme="cancelled">
              Something went wrong when uploading your file
            </DBTag>
          </Box>
        )}
        <Box overflowY="scroll" maxHeight="120px">
          <Box overflow="hidden">
            {errorResults &&
              Object.values(errorResults)
                .filter((err) => err.length > 0)
                .map(
                  (error, i) =>
                    error && (
                      <>
                        <div className="alert">
                          {error
                            .map(
                              (er) => er.description + ' (R:' + er.rowId + ')',
                            )
                            .join(', ')}
                        </div>
                      </>
                    ),
                )}
          </Box>
        </Box>
      </Box>

      <Flex marginTop={32}>
        <DBButton
          variety="create"
          disabled={!fileName || (errorResults && errorResults?.length > 0)}
          onClick={handleUpload}
        >
          Upload
        </DBButton>
        <DBButton variety="cancel" onClick={() => api.setTabIndex(0)}>
          Cancel
        </DBButton>
      </Flex>
    </Box>
  );
};

export default CSVUpload;
