import { CheckCircleOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGroups } from '../../actions/groupActions';
import {
  changeTab,
  cleanSelectedRows,
  setCurrentRow,
  setSearchString,
} from '../../actions/uiActions';
import { Group } from '../../api/backend-api';
import { ModalType } from '../../models/Modal';
import { GeneralState, GroupState } from '../../models/State';
import { formatDate, stringSearch } from '../../utils/Utils';
import DBPopover from '../DBPopover/DBPopover';
import DBTableText from '../DBTableText/DBTableText';
import './GroupsTable.less';

const GroupTable: React.FC = () => {
  const dispatch = useDispatch();
  const { groups, loading, filterValues } = useSelector<
    GeneralState,
    GroupState
  >((state) => state.groups);
  const { searchString } = useSelector<GeneralState, any>((state) => state.ui);

  useEffect(() => {
    dispatch(changeTab('Groups'));
    dispatch(getAllGroups());
    return () => {
      dispatch(setSearchString(''));
      dispatch(cleanSelectedRows());
    };
  }, [dispatch]);

  const columns = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      render: (text: string, record: Group) => (
        <DBTableText
          mainText={`${record.name}`}
          subText={`${record.participantCount} Participants`}
        />
      ),
      sorter: {
        compare: (a: any, b: any) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      },
    },
    {
      key: 'createdOn',
      title: 'Created On',
      dataIndex: 'createdOn',
      render: (text: string, record: Group) => (
        <DBTableText
          mainText={`${formatDate(record.createdOn, 'd MMM yyyy')}`}
          subText={`${formatDate(record.createdOn, 'h:mm a')}`}
        />
      ),
      sorter: {
        compare: (a: any, b: any) =>
          Number(new Date(a.createdOn)) - Number(new Date(b.createdOn)),
      },
    },
    {
      key: 'reviewRequired',
      title: 'Requires Manual Approval',
      dataIndex: 'reviewRequired',
      render: (reviewRequired: boolean) =>
        reviewRequired ? (
          <CheckCircleOutlined style={{ color: '#ffffff', fontSize: 20 }} />
        ) : null,
      filters: !loading ? filterValues['reviewRequired'] : [],
      onFilter: (value: any, record: any) => record.reviewRequired === value,
    },
    {
      key: 'actions',
      title: '',
      dataIndex: 'actions',
      width: 50,
      align: 'center',
      render: () => (
        <DBPopover
          options={[
            {
              name: 'Approval Settings',
              action: ModalType.ApprovalSettingsModal,
            },
            {
              name: 'Delete',
              action: ModalType.DeleteModal,
            },
          ]}
        />
      ),
    },
  ];

  const handleRow = (record: any) => {
    return {
      onClick: () => {
        dispatch(setCurrentRow(record));
      },
    };
  };

  const groupsToShow = searchString
    ? groups.filter((group: Group) => stringSearch(searchString, group.name))
    : groups;

  return (
    <Table
      onRow={handleRow}
      className="GroupsTable"
      size="small"
      rowKey="id"
      pagination={{ showSizeChanger: false }}
      loading={loading}
      columns={columns as any}
      dataSource={groupsToShow}
      getPopupContainer={() => {
        return document.getElementsByClassName('GroupsTable')[0] as HTMLElement;
      }}
    />
  );
};

export default GroupTable;
