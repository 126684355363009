import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../actions/uiActions';
import './DBModal.less';
import { ModalType } from '../../models/Modal';
import UserSettingsModal from './UserSettingsModal';
import AddParticipantModal from './AddParticipantModal';
import AddGroupModal from './AddGroupModal';
import AddRestrictionModal from './AddRestrictionModal';
import ApprovalSettingsModal from './ApprovalSettingsModal';
import DeleteModal from './DeleteModal';
import EditParticipantModal from './EditParticipantModal';
import ViewParticipantModal from './ViewParticipantModal';
import ViewRestrictionModal from './ViewRestrictionModal';
import PendingRequestsModal from './PendingRequestsModal';
import { GeneralState } from '../../models/State';
import EditRestrictionModal from './EditRestrictionModal';
import ConfirmationModal from './ConfirmationModal';
import AddParticipantBulkModal from './AddParticipantBulkModal/AddParticipantBulkModal';
import TradeRequestViewDetailsModal from './TradeRequestViewDetailsModal';
import ResendUserInviteModal from './ResendUserInviteModal';
import UserBulkImportJobElement from './AddParticipantBulkModal/UserBulkImportJobElement';
import ViewApprovalGroupModal from './ViewApprovalGroupModal';
import EditApprovalGroupModal from './EditApprovalGroupModal';
import AddApprovalGroup from './AddApprovalGroup';

const DBModal: React.FC = () => {
  const dispatch = useDispatch();
  const { modalType, isModalOpen, modalWidth } = useSelector<GeneralState, any>(
    (state) => state.ui,
  );

  const handleOnClose = () => {
    dispatch(closeModal());
  };

  const showModalContent = () => {
    switch (modalType) {
      case ModalType.UserSettingsModal:
        return <UserSettingsModal />;
      case ModalType.AddParticipantModal:
        return <AddParticipantModal />;
      case ModalType.AddParticipantBulkModal:
        return (
          <AddParticipantBulkModal>
            <UserBulkImportJobElement />
          </AddParticipantBulkModal>
        );
      case ModalType.AddGroupModal:
        return <AddGroupModal />;
      case ModalType.AddRestrictionModal:
        return <AddRestrictionModal />;
      case ModalType.ApprovalSettingsModal:
        return <ApprovalSettingsModal />;
      case ModalType.ViewParticipantModal:
        return <ViewParticipantModal />;
      case ModalType.EditParticipantModal:
        return <EditParticipantModal />;
      case ModalType.ResendUserInviteModal:
        return <ResendUserInviteModal />;
      case ModalType.ViewRestrictionModal:
        return <ViewRestrictionModal />;
      case ModalType.EditRestrictionModal:
        return <EditRestrictionModal />;
      case ModalType.EditApprovalGroupModal:
        return <EditApprovalGroupModal />;
      case ModalType.PendingRequestModal:
        return <PendingRequestsModal />;
      case ModalType.TradeRequestViewDetailsModal:
        return <TradeRequestViewDetailsModal />;
      case ModalType.ConfirmationModal:
        return <ConfirmationModal />;
      case ModalType.ViewApprovalGroupModal:
        return <ViewApprovalGroupModal />;
      case ModalType.AddApprovalGroup:
        return <AddApprovalGroup />;
      case ModalType.DeleteModal:
        return <DeleteModal />;
      default:
        return null;
    }
  };

  return (
    <Modal
      className="DBModal"
      visible={isModalOpen}
      onCancel={handleOnClose}
      footer={null}
      width={modalWidth}
      destroyOnClose={true}
    >
      {showModalContent()}
    </Modal>
  );
};

export default DBModal;
