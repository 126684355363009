import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  changeTab,
  FilterType,
  openModal,
  setFilterModeActive,
  setSearchString,
} from '../../actions/uiActions';
import { TradeRequest } from '../../api/backend-api';
import { ModalType } from '../../models/Modal';
import { GeneralState } from '../../models/State';
import { Tabs as TabsNames } from '../../models/Tabs';
import { validateRequest } from '../../utils/validateRequest';
import DataTable from '../DataTable/DataTable';
import DBBadge from '../DBBadge/DBBadge';
import DBButton from '../DBButton/DBButton';
import DBFilter from '../DBFilters/DBFilter';
import DBSearchInput from '../DBSearchInput/DBSearchInput';
import { CSVLink } from 'react-csv';
import { populateTransactionsIntoCSV } from '../../utils/dataUtils';
import './DBTab.less';
interface DBTabProps {
  tabs: TabsNames[] | null;
}

const DBTab: React.FC<DBTabProps> = ({ tabs }) => {
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = useState(false);
  const pathName = useLocation().pathname.substr(1);
  const { TabPane } = Tabs;
  const { Participants, PendingRequests, Transactions } = TabsNames;
  const currentDate = new Date();

  const { currentTab, filterModeActive, filterType } = useSelector<
    GeneralState,
    any
  >((state) => state.ui);
  const { groups, loading: loadingGroups } = useSelector<GeneralState, any>(
    (state) => state.groups,
  );
  const { approvalGroups, loading: loadingApprovalGroups } = useSelector<
    GeneralState,
    any
  >((state) => state.approvalGroups);
  const { tradeRequests, loading } = useSelector<GeneralState, any>(
    (state) => state.tradeRequests,
  );
  const { rowsSelected } = useSelector<GeneralState, any>((state) => state.ui);

  const handleChangeTab = (e: string) => {
    if (isMounted) validateRequest(e, dispatch);
    dispatch(changeTab(e));
  };

  const handleChangeSearch = (word: string) => {
    dispatch(setSearchString(word));
  };

  useEffect(() => {
    setIsMounted(true);
  }, [isMounted]);

  const hadleCountChange = () => {
    if (pathName === 'requests' && !loading) {
      const pendingRequests = tradeRequests.filter(
        (tradeRequest: TradeRequest) => tradeRequest.status === 'PENDING',
      );
      return pendingRequests.length;
    } else {
      return 0;
    }
  };

  const getAllGroupNames = () => {
    return !loadingGroups ? groups.map((group: any) => group.name) : [];
  };
  const getAllApprovalGroupsNames = () => {
    return !loadingApprovalGroups
      ? approvalGroups.map((group: any) => group.name)
      : [];
  };

  const getPlaceholder = () => {
    return currentTab === Participants ? 'Search name' : 'Search';
  };

  const handleSubmit = async () => {
    dispatch(openModal(ModalType.ConfirmationModal, rowsSelected.length));
  };

  const transactionsCSVTable = populateTransactionsIntoCSV(tradeRequests);

  const renderExtraContent = () => {
    return (
      <span className="tabExtraContent">
        {currentTab === Participants ? (
          filterModeActive && filterType === FilterType.groups ? (
            <DBFilter
              multiple
              placeholder="Select groups"
              selectValues={getAllGroupNames()}
              radioValues={['add', 'remove']}
              onSubmit={handleSubmit}
            />
          ) : filterModeActive && filterType === FilterType.approvalGroup ? (
            <DBFilter
              multiple={false}
              placeholder="Select approval groups"
              selectValues={getAllApprovalGroupsNames()}
              radioValues={['add', 'remove']}
              onSubmit={handleSubmit}
            />
          ) : (
            <>
              <DBButton
                onClick={() =>
                  dispatch(setFilterModeActive(true, FilterType.groups))
                }
              >
                Edit groups
              </DBButton>
              <DBButton
                style={{ marginLeft: '1rem' }}
                onClick={() =>
                  dispatch(setFilterModeActive(true, FilterType.approvalGroup))
                }
              >
                Edit approval groups
              </DBButton>
            </>
          )
        ) : null}
        {currentTab === Transactions ? (
          <div className="DBButton ant-btn">
            <CSVLink
              filename={`Total Transactions at ${currentDate}`}
              data={transactionsCSVTable.data}
              headers={transactionsCSVTable.headers}
            >
              Export Requests
            </CSVLink>
          </div>
        ) : null}

        <DBSearchInput
          style={{ minWidth: 200, marginLeft: '1rem' }}
          placeholder={getPlaceholder()}
          className="extraContentAutocomplete"
          onChange={handleChangeSearch}
        />
      </span>
    );
  };

  return (
    <div className="DBTab">
      <Tabs
        key={pathName}
        onChange={handleChangeTab}
        tabBarExtraContent={renderExtraContent()}
      >
        {tabs &&
          tabs.map((tab) => (
            <TabPane
              key={tab}
              tab={
                tab !== PendingRequests ? (
                  <span>{tab}</span>
                ) : (
                  <span>
                    {tab}
                    <DBBadge
                      className="tab-badge"
                      onCountChange={hadleCountChange}
                    />
                  </span>
                )
              }
            >
              <DataTable tab={tab} />
            </TabPane>
          ))}
      </Tabs>
    </div>
  );
};

export default DBTab;
