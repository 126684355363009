import { Col, Layout, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveTrades,
  getActiveTradesPerMonth,
} from '../../actions/activeTradeActions';
import { getAllRestrictions } from '../../actions/restrictionsActions';
import { getAllTradeRequests } from '../../actions/tradeRequestActions';
import { changeTab } from '../../actions/uiActions';
import { GeneralState } from '../../models/State';
import ActiveApprovalsTable from '../DataTable/ActiveApprovalsTable';
import RestrictionsSummaryTable from '../DataTable/RestrictionsSummaryTable';
import DBStatisticCard from '../DBWidgets/DBStatisticCard';
import './OverviewDashboard.less';

const { Content } = Layout;

const OverviewDashboard: React.FC = () => {
  const dispatch = useDispatch();

  const { restriction, activeTrades }: GeneralState = useSelector<
    GeneralState,
    any
  >((state) => state);

  const { restrictions: restrictionsList, loading: loadingRestrictions } =
    restriction;

  const [staticticState] = useState([
    'Pending Approvals',
    'Approved Trade Volume',
    'Approved Requests',
    'Declined Requests',
  ]);

  useEffect(() => {
    dispatch(getActiveTradesPerMonth());
    dispatch(getActiveTrades());
    dispatch(getAllTradeRequests());
    dispatch(getAllRestrictions());
    dispatch(changeTab(''));
  }, [dispatch]);

  return (
    <Content className="OverviewDashboard">
      <h2 className="title">Trade Request Metrics - Last 30 Days</h2>
      <Row gutter={16}>
        {staticticState.map((title) => (
          <Col key={title} span={6}>
            <DBStatisticCard
              title={title}
              tradeRequests={activeTrades.activeTradesPerMonth}
            />
          </Col>
        ))}
      </Row>

      <Row gutter={16} style={{ paddingTop: 30 }}>
        <Col span={12}>
          <ActiveApprovalsTable
            tradeRequests={activeTrades.activeTrades}
            loading={activeTrades.loading}
          />
        </Col>
        <Col span={12}>
          <RestrictionsSummaryTable
            restrictions={restrictionsList}
            loading={loadingRestrictions}
          />
        </Col>
      </Row>
    </Content>
  );
};

export default OverviewDashboard;
