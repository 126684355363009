import React, { useEffect } from 'react';
import DBButton from '../DBButton/DBButton';
import { Formik, Form, FormikProps } from 'formik';
import './EditRestrictionModal.less';
import DBInputTextField from '../DBForm/DBInputTextField';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../actions/uiActions';
import DBCheckBoxField from '../DBForm/DBCheckBoxField';
import { getAllGroups } from '../../actions/groupActions';
import { GeneralState, GroupState } from '../../models/State';
import { Loader } from '../Loader/Loader';
import { Restriction } from '../../api/backend-api';
import DBModalHeader from './DBModalHeader';
import DBCalendar from '../DBCalendar/DBCalendar';
import { updateRestriction } from '../../actions/restrictionsActions';
import isBefore from 'date-fns/isBefore';
import { formatISO } from 'date-fns';
import { validateRequest } from '../../utils/validateRequest';

interface Values {
  name: string;
  description: string;
  duration: { start: any; end: any };
  groups: string[];
}

const EditRestrictionModal: React.FC = () => {
  const dispatch = useDispatch();
  const { groups, loading } = useSelector<GroupState, any>(
    (state) => state.groups,
  );
  const { currentRow, currentTab } = useSelector<GeneralState, any>(
    (state) => state.ui,
  );

  useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const setIntervalValues = (date: Date[]) => {
    const parsedDate = new Date(date[0]);
    if (isBefore(new Date(currentRow.startDate), new Date())) {
      return { start: new Date(currentRow.startDate), end: date[1] };
    }
    return { start: parsedDate, end: date[1] };
  };

  return (
    <DBModalHeader title="Edit Restriction" className="EditRestrictionModal">
      <Formik
        validateOnBlur={true}
        validateOnChange={false}
        validate={async (values) => {
          const errors: any = {};
          if (!values.name) {
            errors.name = 'You must enter a name';
          }

          return errors;
        }}
        initialValues={{
          name: currentRow.name,
          description: currentRow.description,
          duration: { start: currentRow.startDate, end: currentRow.endDate },
          groups: currentRow.groups,
        }}
        onSubmit={(values, actions) => {
          dispatch(
            updateRestriction(currentRow.id, {
              ...values,
              startDate: formatISO(new Date(values.duration.start) as any, {
                representation: 'date',
              }),
              endDate: formatISO(new Date(values.duration.end) as any, {
                representation: 'date',
              }),
            } as unknown as Restriction),
          );
          actions.setSubmitting(false);
          dispatch(closeModal());
          setTimeout(() => validateRequest(currentTab, dispatch), 500);
        }}
      >
        {(props: FormikProps<Values>) => {
          return (
            <Form className="ant-form ant-form-vertical">
              <DBInputTextField
                name="name"
                type="text"
                label="Name"
                onChange={props.handleChange}
              />
              <DBInputTextField
                name="description"
                type="text"
                label="Description"
                onChange={props.handleChange}
              />
              {loading ? (
                <Loader />
              ) : (
                <DBCheckBoxField
                  groups={groups}
                  checkedGroups={props.values.groups}
                  onChange={(selected: string[]) =>
                    props.setFieldValue('groups', selected)
                  }
                />
              )}
              <DBCalendar
                label="Duration"
                selectRange={true}
                allowPartialRange={true}
                value={[
                  new Date(props.values.duration.start),
                  new Date(props.values.duration.end),
                ]}
                onChange={(date) => {
                  const interval = setIntervalValues(date);
                  props.setFieldValue('duration', interval);
                }}
              />
              <div className="buttonsContainer">
                <DBButton
                  variety="create"
                  disabled={!(props.dirty && props.isValid)}
                  htmlType="submit"
                >
                  Save
                </DBButton>
                <DBButton variety="cancel" onClick={handleCancel}>
                  Cancel
                </DBButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </DBModalHeader>
  );
};

export default EditRestrictionModal;
