import React from 'react';
import { useField, FieldProps } from 'formik';
import { Form, Input } from 'antd';

const DBInputTextField: React.FC<any> = ({ label, ...props }) => {
  const [field, meta] = useField<FieldProps>(props);

  return (
    <Form.Item
      label={label}
      help={meta.error && meta.touched ? meta.error : null}
      validateStatus={meta.error && meta.touched ? 'error' : undefined}
    >
      <Input {...field} {...props} />
    </Form.Item>
  );
};

export default DBInputTextField;
