import React from 'react';
import { Menu, Popover } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useState } from 'react';
import './DBPopover.less';
import { ModalType } from '../../models/Modal';
import { useDispatch } from 'react-redux';
import { openModal } from '../../actions/uiActions';

interface Option {
  name: string;
  action: ModalType;
}
interface DBPopoverProps {
  options?: Option[];
}

const DBPopover: React.FC<DBPopoverProps> = ({ options }) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleClick = (action: ModalType) => {
    setIsVisible(false);
    dispatch(openModal(action));
  };

  const menu = (
    <Menu className="PopoverMenu">
      {options!.map((option) => (
        <Menu.Item key={option.name} onClick={() => handleClick(option.action)}>
          {option.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const propsPopOver = {
    overlayClassName: 'DBPopover',
    trigger: 'click',
    visible: isVisible,
    onVisibleChange: (visible: boolean) => setIsVisible(visible),
  };

  return (
    <div className="MenuSettings">
      <Popover placement="leftTop" content={menu} {...propsPopOver}>
        <MoreOutlined
          style={{ color: 'white', fontSize: 20, fontWeight: 'bolder' }}
        />
      </Popover>
    </div>
  );
};

export default DBPopover;
