import React, { useEffect } from 'react';
import DBButton from '../DBButton/DBButton';
import { Formik, Form, FormikProps } from 'formik';
import './AddRestrictionModal.less';
import DBInputTextField from '../DBForm/DBInputTextField';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../actions/uiActions';
import DBCheckBoxField from '../DBForm/DBCheckBoxField';
import { getAllGroups } from '../../actions/groupActions';
import { GeneralState, GroupState } from '../../models/State';
import { Loader } from '../Loader/Loader';
import DBCalendar from '../DBCalendar/DBCalendar';
import DBModalHeader from './DBModalHeader';
import { addRestriction } from '../../actions/restrictionsActions';
import { Restriction } from '../../api/backend-api';
import { formatISO } from 'date-fns';
import { validateRequest } from '../../utils/validateRequest';

interface Values {
  name: string;
  description: string;
  duration: { start: any; end: any };
  groups: string[];
}

const AddRestrictionModal: React.FC = () => {
  const dispatch = useDispatch();
  const { groups, loading } = useSelector<GroupState, any>(
    (state) => state.groups,
  );
  const { currentTab } = useSelector<GeneralState, any>((state) => state.ui);

  const handleCancel = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);

  return (
    <DBModalHeader title="Add Restriction" className="AddRestrictionModal">
      <Formik
        validate={(values) => {
          const errors: any = {};
          if (!values.name) {
            errors.name = 'You must enter a name';
          }
          if (!values.duration.start) {
            errors.duration = 'You must enter a period';
          }
          if (!values.duration.end) {
            errors.duration = 'You must enter a period';
          }

          return errors;
        }}
        initialValues={{
          name: '',
          description: '',
          duration: { start: undefined, end: undefined },
          groups: [],
        }}
        onSubmit={(values, actions) => {
          dispatch(
            addRestriction({
              ...values,
              startDate: formatISO(values.duration.start as any, {
                representation: 'date',
              }),
              endDate: formatISO(values.duration.end as any, {
                representation: 'date',
              }),
            } as unknown as Restriction),
          );
          actions.setSubmitting(false);
          dispatch(closeModal());
          setTimeout(() => validateRequest(currentTab, dispatch), 500);
        }}
      >
        {(props: FormikProps<Values>) => {
          return (
            <Form className="ant-form ant-form-vertical">
              <DBInputTextField name="name" type="text" label="Name" />
              <DBInputTextField
                name="description"
                type="text"
                label="Description"
              />
              {loading ? (
                <Loader />
              ) : (
                <DBCheckBoxField
                  groups={groups}
                  onChange={(selected: string[]) =>
                    props.setFieldValue('groups', selected)
                  }
                />
              )}
              <DBCalendar
                label="Duration"
                selectRange={true}
                onChange={(date) => {
                  const interval = { start: date[0], end: date[1] };
                  props.setFieldValue('duration', interval);
                }}
              />
              <div className="buttonsContainer">
                <DBButton
                  variety="create"
                  disabled={!(props.dirty && props.isValid)}
                  htmlType="submit"
                >
                  Create
                </DBButton>
                <DBButton variety="cancel" onClick={handleCancel}>
                  Cancel
                </DBButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </DBModalHeader>
  );
};

export default AddRestrictionModal;
