import React, { useEffect } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Restriction, RestrictionStatusEnum } from '../../api/backend-api';
import DBTableText from '../DBTableText/DBTableText';
import {
  compareRestrictions,
  formatDate,
  getParentNode,
  stringSearch,
} from '../../utils/Utils';
import DBTag from '../DBTag/DBTag';

import './RestrictionsTable.less';
import DBPopover from '../DBPopover/DBPopover';
import { GeneralState } from '../../models/State';
import { Tabs as TabsNames } from '../../models/Tabs';
import {
  changeTab,
  setCurrentRow,
  setSearchString,
} from '../../actions/uiActions';
import { ModalType } from '../../models/Modal';
import { getAllRestrictions } from '../../actions/restrictionsActions';

const RestrictionsTable: React.FC = () => {
  const dispatch = useDispatch();
  const { All, Active, Future, Ended } = TabsNames;
  const { restriction, ui } = useSelector<GeneralState, any>((state) => state);

  const { restrictions: restrictionsList, loading, filterValues } = restriction;
  const { currentTab, searchString } = ui;

  const setPopoverOptions = (status: RestrictionStatusEnum | undefined) => {
    switch (status) {
      case RestrictionStatusEnum.Active:
        return [
          { name: 'View', action: ModalType.ViewRestrictionModal },
          { name: 'Edit', action: ModalType.EditRestrictionModal },
        ];
      case RestrictionStatusEnum.Future:
        return [
          { name: 'View', action: ModalType.ViewRestrictionModal },
          { name: 'Edit', action: ModalType.EditRestrictionModal },
          { name: 'Delete', action: ModalType.DeleteModal },
        ];
      case RestrictionStatusEnum.Ended:
        return [{ name: 'View', action: ModalType.ViewRestrictionModal }];
      default:
        return [];
    }
  };

  const columns = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      render: (text: string) => <DBTableText mainText={text} />,
      sorter: {
        compare: (a: any, b: any) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      },
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
      ellispis: true,
      render: (text: string) => <DBTableText mainText={text ? text : ''} />,
      sorter: {
        compare: (a: any, b: any) =>
          a.description
            ? a.description
                .toLowerCase()
                .trim()
                .localeCompare(
                  b.description ? b.description.toLowerCase().trim() : '',
                )
            : ''
                .toLowerCase()
                .trim()
                .localeCompare(
                  b.description ? b.description.toLowerCase().trim() : '',
                ),
      },
    },
    {
      key: 'restrictedWindow',
      title: 'Restricted Window',
      dataIndex: 'restrictedWindow',
      render: (text: string, record: Restriction) => (
        <DBTableText
          mainText={`${formatDate(
            record.startDate,
            'd MMM yyyy',
          )} - ${formatDate(record.endDate, 'd MMM yyyy')}`}
        />
      ),
      sorter: {
        compare: (a: any, b: any) => compareRestrictions(a, b),
      },
    },
    {
      key: 'groups',
      title: 'Groups',
      dataIndex: 'groups',
      render: (groups: string[]) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {groups.map((group) => (
            <DBTag key={group} theme="dark">
              {group}
            </DBTag>
          ))}
        </span>
      ),
      filters: !loading ? filterValues['groups'] : [],
      onFilter: (value: any, record: any) => record.groups.includes(value),
    },
    {
      key: 'actions',
      title: '',
      dataIndex: 'actions',
      width: 50,
      align: 'center',
      render: (index: string, record: Restriction) => {
        return <DBPopover options={setPopoverOptions(record.status)} />;
      },
    },
  ];

  useEffect(() => {
    switch (currentTab) {
      case All:
        dispatch(getAllRestrictions());
        break;
      case Active:
        dispatch(getAllRestrictions(undefined, 'ACTIVE'));
        break;
      case Future:
        dispatch(getAllRestrictions(undefined, 'FUTURE'));
        break;
      case Ended:
        dispatch(getAllRestrictions(undefined, 'ENDED'));
        break;
      default:
        dispatch(changeTab('All'));
        dispatch(getAllRestrictions());
        break;
    }

    return () => {
      dispatch(setSearchString(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const restrictionsToShow = searchString
    ? restrictionsList.filter((res: any) =>
        stringSearch(searchString, `${res.name} ${[res?.groups].join()}`),
      )
    : restrictionsList;

  const handleRow = (record: any) => {
    return {
      onClick: () => {
        dispatch(setCurrentRow(record));
      },
    };
  };

  return (
    <Table
      onRow={handleRow}
      className="RestrictionsTable"
      rowKey="id"
      size="middle"
      pagination={{ showSizeChanger: false }}
      loading={loading}
      columns={columns as any}
      dataSource={restrictionsToShow}
      getPopupContainer={(trigger: any) => {
        return getParentNode(trigger, 11);
      }}
    />
  );
};

export default RestrictionsTable;
