import React, { useEffect } from 'react';
import DBButton from '../DBButton/DBButton';
import { Formik, Form, FormikProps } from 'formik';
import './AddGroupModal.less';
import * as Yup from 'yup';
import DBInputTextField from '../DBForm/DBInputTextField';
import { ApprovalGroup, User, UserLite } from '../../api/backend-api';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../actions/uiActions';
import DBModalHeader from './DBModalHeader';
import { Form as FormAntd } from 'antd';
import DBSelect from '../DBFilters/DBSelect';
import { GeneralState, UserState } from '../../models/State';
import { getAllUsers } from '../../actions/userActions';
import './AddApprovalGroup.less';
import {
  addApprovalGroup,
  getAllApprovalGroups,
} from '../../actions/approvalGroupActions';

interface Values {
  approvalGroupName: string;
  approvalManagers?: UserLite[];
}

// const checkGroupNameExist = async (name: string) => {
//   try {
//     const res: Group[] | any = await api.getGroupByName(name);
//     return !res.length;
//   } catch (err) {
//     console.log(err);
//     return true;
//   }
// };

const checkGroupValueHasDashes = (name: string) => {
  if (name) {
    if (name.match(/[-]/)?.length) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const ValidationSchema = Yup.object().shape({
  approvalGroupName: Yup.string()
    .required('Group name cannot be blank')
    .test(
      'shouldNotBeLongerThan20Characters',
      'Name should not be longer than 20 characters',
      (value) => value!?.length <= 20,
    )
    .test(
      'shouldNotContainsDash',
      'Cannot use dash separator for group name',
      (value) => checkGroupValueHasDashes(value as string),
    ),
  // .test('groupAlreadyExist', 'Name is already in use', (value) => {
  //   return checkGroupNameExist(value as string);
  // }),
});

const AddApprovalGroup: React.FC = () => {
  const dispatch = useDispatch();
  const { users, loading } = useSelector<GeneralState, UserState>(
    (state) => state.users,
  );

  const handleCancel = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  return (
    <DBModalHeader title="Add Approval Group" className="AddApprovalGroup">
      <Formik
        validationSchema={ValidationSchema}
        initialValues={{
          approvalGroupName: '',
          approvalManagers: [],
        }}
        onSubmit={(values, actions) => {
          console.log(values);

          dispatch(
            addApprovalGroup({
              name: values.approvalGroupName,
              approvalManagers: values.approvalManagers.map((manager) => ({
                email: manager,
                fullName: users
                  .filter((user: User) => manager === user.email)
                  .map((user: any) => `${user.firstName} ${user.lastName}`)[0],
              })),
            } as unknown as ApprovalGroup),
          );
          actions.setSubmitting(false);
          dispatch(closeModal());
          setTimeout(() => dispatch(getAllApprovalGroups()), 1000);
        }}
      >
        {(props: FormikProps<Values>) => {
          return (
            <Form className="ant-form ant-form-vertical">
              <DBInputTextField
                name="approvalGroupName"
                type="text"
                label="Group Name"
              />
              <FormAntd.Item label="Add Managers" className="SelectGroupField">
                <DBSelect
                  mode="multiple"
                  placeholder="Select managers"
                  options={users
                    .filter((user) => user.isManager)
                    .map((user, index) => ({
                      key: user.id || index,
                      label: user.email,
                      value: user.email,
                    }))}
                  value={props.getFieldProps('approvalManagers').value}
                  theme="light"
                  dropdownClassName="DBSelectDropdownLight"
                  loading={loading}
                  onChange={(selected: string[]) =>
                    props.setFieldValue('approvalManagers', selected)
                  }
                />
              </FormAntd.Item>
              <div className="buttonsContainer">
                <DBButton
                  variety="create"
                  htmlType="submit"
                  disabled={!(props.dirty && props.isValid)}
                >
                  Create
                </DBButton>
                <DBButton variety="cancel" onClick={handleCancel}>
                  Cancel
                </DBButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </DBModalHeader>
  );
};

export default AddApprovalGroup;
