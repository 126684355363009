import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Collapse, Empty } from 'antd';
import { formatDate } from '../../../utils/Utils';
import { translateErrorDetails } from '../../../utils/bulkImportErrorMessages';
import DBTag from '../../DBTag/DBTag';
import { UserBulkImportJob } from '../../../api/backend-api';
import { api } from '../../../api/api';
import Box from '../../Box/Box';
import Flex from '../../Flex/Flex';

const { Panel } = Collapse;

const userBulkImportJobElement = (el: UserBulkImportJob) => (
  <Flex
    key={el.id}
    justifyContent="space-between"
    alignItems="center"
    padding="8px 12px"
    cursor="unset"
    _hover={{
      backgroundColor: '#f7fafc',
    }}
  >
    <Box>
      <div>Uploaded on {formatDate(el.createdOn, 'd MMM yyyy')}</div>
      <div>
        Status:&nbsp;
        {(el.status === 'IN_PROGRESS' || el.status === 'PENDING') &&
          `Processing ${el.participantCount} users`}
        {el.status === 'COMPLETED' &&
          `Successfully uploaded ${el.participantCount} users`}
        {el.status === 'ERROR' &&
          `Errors found in upload (${el.participantCount} users)`}
      </div>
    </Box>
    <DBTag
      theme={
        (el.status === 'COMPLETED' && 'approved') ||
        (el.status === 'ERROR' && 'cancelled') ||
        'future'
      }
    >
      {(el.status === 'IN_PROGRESS' || el.status === 'PENDING') && (
        <LoadingOutlined
          style={{
            fontSize: 12,
            paddingLeft: 2,
            paddingRight: 4,
          }}
          spin
        />
      )}
      {el.status === 'ERROR' && 'Issues Found'}
      {el.status === 'IN_PROGRESS' && 'In Progress'}
      {el.status === 'PENDING' && 'Pending'}
      {el.status === 'COMPLETED' && 'Completed'}
    </DBTag>
  </Flex>
);

const UserBulkImportJobElement = () => {
  const [bulkImport, setBulkImport] = useState<UserBulkImportJob[]>();

  let bulkImportJob = async () => {
    let res = await api.listUserBulkImportJobs();
    setBulkImport(res);
  };

  useEffect(() => {
    bulkImportJob();
    const intervalId = setInterval(() => {
      bulkImportJob();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box overflow="hidden">
      <Box maxHeight="232px" overflow="scroll">
        {!bulkImport ? (
          <>
            <div>
              Upload tasks will show here, alongside their upload status{' '}
            </div>
            <Flex width="100%" minHeight="110px" justifyContent="center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Flex>
          </>
        ) : (
          <Collapse expandIconPosition={'right'} bordered={false} ghost={true}>
            {bulkImport
              .sort(
                (a, b) =>
                  Number(new Date(b.createdOn as string)) -
                  Number(new Date(a.createdOn as string)),
              )
              .map((job) =>
                job.status === 'ERROR' && 'Issues Found' ? (
                  <Panel
                    header={userBulkImportJobElement(job)}
                    showArrow={true}
                    key={job.id || ''}
                  >
                    <div>{translateErrorDetails(job)}</div>
                  </Panel>
                ) : (
                  userBulkImportJobElement(job)
                ),
              )}
          </Collapse>
        )}
      </Box>
    </Box>
  );
};

export default UserBulkImportJobElement;
