import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cleanSelectedRows,
  closeModal,
  FilterType,
  setFilterModeActive,
  setSelectedGroup,
} from '../../actions/uiActions';
import { GeneralState, UIState } from '../../models/State';
import DBButton from '../DBButton/DBButton';
import DBModalHeader from './DBModalHeader';
import ModalTextField from './ModalTextField';
import './ConfirmationModal.less';
import { api } from '../../api/api';
import { getAllUsers } from '../../actions/userActions';

const ConfirmationModal: React.FC = () => {
  const dispatch = useDispatch();
  const { modalData, actionFilter, rowsSelected, groupsSelected, filterType } =
    useSelector<GeneralState, UIState>((state) => state.ui);

  const isApprovalGroup = filterType === FilterType.approvalGroup;

  const handleSubmit = async () => {
    try {
      if (isApprovalGroup) {
        await api.bulkUpdateUserApprovalGroup({
          action: actionFilter.toUpperCase(),
          users: rowsSelected.map((row: any) => row.email),
          approvalGroup: groupsSelected[0],
        });
      } else {
        await api.bulkUpdateUserGroups({
          action: actionFilter.toUpperCase(),
          users: rowsSelected.map((row: any) => row.email),
          groups: groupsSelected,
        });
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(setFilterModeActive(false));
    dispatch(cleanSelectedRows());
    dispatch(setSelectedGroup([]));
    dispatch(closeModal());
    setTimeout(() => dispatch(getAllUsers()), 500);
  };

  const handleCancel = () => {
    dispatch(closeModal());
  };

  return (
    <DBModalHeader
      title={`Edit ${isApprovalGroup ? 'Approval' : 'Participant'} Groups`}
      className="ConfirmationModal"
    >
      <ModalTextField
        description={`${
          isApprovalGroup
            ? ''
            : `You are about to modify the groups of ${modalData} participant/s.`
        } Do you want to continue?`}
      />
      <div className="buttonsContainer">
        <DBButton variety="create" htmlType="submit" onClick={handleSubmit}>
          Submit
        </DBButton>
        <DBButton variety="cancel" onClick={handleCancel}>
          Cancel
        </DBButton>
      </div>
    </DBModalHeader>
  );
};

export default ConfirmationModal;
