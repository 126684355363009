import React from 'react';
import { AutoComplete } from 'antd';
import './DBSearchInput.less';

interface DBSearchInputProps {
  options?: any;
  style?: any;
  className?: string;
  placeholder?: string;
  onChange?: (a: string) => void;
}

const DBSearchInput: React.FC<DBSearchInputProps> = ({
  options,
  style,
  className,
  placeholder,
  onChange,
}) => {
  return (
    <AutoComplete
      options={options}
      style={style}
      className={className}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default DBSearchInput;
