import React, { useEffect } from 'react';
import DBButton from '../DBButton/DBButton';
import { Formik, Form, FormikProps } from 'formik';
import { Form as FormAntd } from 'antd';
import './EditParticipantModal.less';
import DBInputTextField from '../DBForm/DBInputTextField';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../actions/uiActions';
import DBSwitchField from '../DBForm/DBSwitchField';
import { getAllUsers, updateUser } from '../../actions/userActions';
import { getAllApprovalGroups } from '../../actions/approvalGroupActions';
import { getAllGroups } from '../../actions/groupActions';
import {
  ApprovalGroupState,
  GeneralState,
  GroupState,
} from '../../models/State';
import { Group, User } from '../../api/backend-api';
import DBModalHeader from './DBModalHeader';
import { useAuth } from '../../context/auth/authContext';
import DBSelect from '../DBFilters/DBSelect';

interface Values {
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  isManager: boolean;
  groups: string[];
  approvalGroup: string;
}

const EditParticipantModal: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { approvalGroups, approvalGroupsLoading } = useSelector<
    ApprovalGroupState,
    any
  >((state) => state.approvalGroups);
  const { groups, loading } = useSelector<GroupState, any>(
    (state) => state.groups,
  );
  const { currentRow } = useSelector<GeneralState, any>((state) => state.ui);
  const isCurrentUser = user?.email === currentRow.email;

  const handleCancel = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    dispatch(getAllGroups());
    dispatch(getAllApprovalGroups());
  }, [dispatch]);

  return (
    <DBModalHeader title="Edit Participant" className="EditParticipantModal">
      <Formik
        validateOnBlur={true}
        validateOnChange={false}
        validate={async (values) => {
          const errors: any = {};
          if (values.firstName.length <= 0) {
            errors.firstName = 'You must enter a first name';
          }
          if (values.lastName.length <= 0) {
            errors.lastName = 'You must enter a last name';
          }
          if (values.jobTitle.length <= 0) {
            errors.jobTitle = 'You must enter a job title';
          }

          return errors;
        }}
        initialValues={{
          firstName: currentRow.firstName,
          lastName: currentRow.lastName,
          jobTitle: currentRow.jobTitle,
          email: currentRow.email,
          isManager: currentRow.isManager,
          groups: currentRow.groups,
          approvalGroup: currentRow.approvalGroup,
        }}
        onSubmit={(values, actions) => {
          dispatch(updateUser(currentRow.id, { ...values } as unknown as User));
          actions.setSubmitting(false);
          dispatch(closeModal());
          setTimeout(() => dispatch(getAllUsers()), 1500);
          actions.setSubmitting(false);
        }}
      >
        {(props: FormikProps<Values>) => {
          return (
            <Form className="ant-form ant-form-vertical">
              <DBSwitchField
                label="Create as manager"
                defaultChecked={currentRow.isManager}
                disabled={isCurrentUser}
                onChange={(value: boolean) =>
                  props.setFieldValue('isManager', value)
                }
              />
              <DBInputTextField
                name="firstName"
                type="text"
                label="First Name"
                onChange={props.handleChange}
              />
              <DBInputTextField
                name="lastName"
                type="text"
                label="Last Name"
                onChange={props.handleChange}
              />
              <DBInputTextField
                name="jobTitle"
                type="text"
                label="Job Title"
                onChange={props.handleChange}
              />
              <DBInputTextField
                name="email"
                type="text"
                label="Email"
                value={currentRow.email}
                disabled={true}
              />
              <FormAntd.Item label="Groups" className="SelectGroupField">
                <DBSelect
                  mode="multiple"
                  placeholder="Select groups"
                  options={groups.map((group: Group) => ({
                    key: group.id,
                    label: group.name,
                    value: group.name,
                  }))}
                  value={props.getFieldProps('groups').value}
                  theme="light"
                  dropdownClassName="DBSelectDropdownLight"
                  loading={loading}
                  onChange={(selected: string[]) =>
                    props.setFieldValue('groups', selected)
                  }
                />
              </FormAntd.Item>
              <FormAntd.Item
                label="Approval Group"
                className="SelectApprovalGroupField"
              >
                <DBSelect
                  placeholder="Select approval group"
                  options={approvalGroups.map((group: Group) => ({
                    key: group.id,
                    label: group.name,
                    value: group.name,
                  }))}
                  dropdownClassName="DBSelectDropdownLight"
                  value={props.getFieldProps('approvalGroup').value}
                  allowClear={true}
                  theme="light"
                  loading={approvalGroupsLoading}
                  onChange={(selected: string[]) =>
                    props.setFieldValue('approvalGroup', selected)
                  }
                />
              </FormAntd.Item>
              <div className="buttonsContainer">
                <DBButton
                  variety="create"
                  disabled={!(props.dirty && props.isValid)}
                  htmlType="submit"
                >
                  Save
                </DBButton>
                <DBButton variety="cancel" onClick={handleCancel}>
                  Cancel
                </DBButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </DBModalHeader>
  );
};

export default EditParticipantModal;
