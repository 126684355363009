import React from 'react';
import { Button, ButtonProps } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import './DBButton.less';

interface DBButtonProps {
  size?: SizeType;
  variety?: 'create' | 'cancel' | 'delete';
  icon?: React.ReactElement;
  htmlType?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  type?:
    | 'default'
    | 'link'
    | 'text'
    | 'ghost'
    | 'primary'
    | 'dashed'
    | undefined;
  onClick?: () => void;
}

const DBButton: React.FC<DBButtonProps & React.HTMLAttributes<ButtonProps>> = ({
  children,
  size = 'middle',
  variety = '',
  icon,
  type = undefined,
  htmlType,
  disabled,
  onClick,
  ...rest
}) => {
  return (
    <Button
      className={`DBButton ${variety}`}
      size={size}
      {...rest}
      type={type}
      icon={icon}
      onClick={onClick}
      htmlType={htmlType}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default DBButton;
