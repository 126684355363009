import React from 'react';
import { Layout, Menu, Tooltip } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  ExportOutlined,
  QuestionOutlined,
  UserOutlined,
} from '@ant-design/icons';
import DBButton from '../DBButton/DBButton';
import './Sidebar.less';
import { useDispatch } from 'react-redux';
import { openModal } from '../../actions/uiActions';
import { ModalType } from '../../models/Modal';
import { useAuth } from '../../context/auth/authContext';

const { Sider } = Layout;

const Sidebar: React.FC = () => {
  const dispatch = useDispatch();
  const { logout, user } = useAuth();
  const pathName = useLocation().pathname.substr(1);

  return (
    <Sider width={240} className="Sidebar">
      <div className="DBLogo">
        <Tooltip
          placement="bottom"
          title={`Build ${process.env.REACT_APP_VERSION}`}
        >
          <img
            className="DBImage"
            src={process.env.PUBLIC_URL + '/images/drawbridge_logo_white.svg'}
            alt="Drawbridge Logo"
          />
        </Tooltip>
      </div>

      <Menu theme="dark" mode="inline" selectedKeys={[`${pathName}`]}>
        <Menu.Item key="overview">
          <Link to="/overview">Overview</Link>
        </Menu.Item>
        <Menu.Item key="requests">
          <Link to="/requests">Requests</Link>
        </Menu.Item>
        <Menu.Item key="participants">
          <Link to="/participants">Participants</Link>
        </Menu.Item>
        <Menu.Item key="groups">
          <Link to="/groups">Groups</Link>
        </Menu.Item>
        <Menu.Item key="restrictions">
          <Link to="/restrictions">Restrictions</Link>
        </Menu.Item>
      </Menu>
      <div className="buttonsContainer">
        <DBButton size="large" id="helpButton" icon={<QuestionOutlined />}>
          Help
        </DBButton>
        <DBButton
          size="large"
          icon={<UserOutlined />}
          onClick={() => dispatch(openModal(ModalType.UserSettingsModal))}
        >
          {user?.name}
        </DBButton>
        <DBButton size="large" icon={<ExportOutlined />} onClick={logout}>
          Sign out
        </DBButton>
      </div>
    </Sider>
  );
};

export default Sidebar;
