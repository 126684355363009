import React from 'react';
import './DBModalHeader.less';

interface DBModalHeaderProps {
  title?: string;
  header?: any;
  className?: string;
}

const DBModalHeader: React.FC<DBModalHeaderProps> = ({
  title,
  header = null,
  className = '',
  children,
}) => {
  return (
    <div className="DBModalHeader">
      <h2 className="user-modal-title">{title}</h2>
      {header}
      <div className={className}>{children}</div>
    </div>
  );
};

export default DBModalHeader;
