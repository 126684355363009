import { Divider } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCurrentUser } from '../../actions/authActions';
import { resetPassword } from '../../api/api';
import { useAuth } from '../../context/auth/authContext';
import { GeneralState } from '../../models/State';
import DBButton from '../DBButton/DBButton';
import { Loader } from '../Loader/Loader';
import UserHeader from '../UserHeader/UserHeader';
import DBModalHeader from './DBModalHeader';
import ModalTextField from './ModalTextField';

import './UserSettingsModal.less';

const UserSettingsModal: React.FC = () => {
  const dispatch = useDispatch();
  const { user: me } = useAuth();
  const [message, setMessage] = useState<string>('');
  const { currentUser, loading } = useSelector<GeneralState, any>(
    (state) => state.auth,
  );

  useEffect(() => {
    dispatch(setCurrentUser(me!.userId));
  }, [dispatch, me]);

  const handleResetPassword = async () => {
    try {
      const response = await resetPassword(currentUser?.email);
      setMessage(response.data);
    } catch (err) {
      setMessage(
        JSON.stringify((err as any).message) || 'Something went wrong',
      );
    }
  };

  if (!currentUser && loading) {
    return <Loader />;
  }

  if (!currentUser) {
    return (
      <div className="UserSettingsModalError">
        <div className="title">Something Goes Wrong.</div>
        <div className="subtitle">Try again clicking in the button below.</div>
        <DBButton
          style={{ width: 240 }}
          onClick={() => dispatch(setCurrentUser(me!.userId))}
        >
          Retry
        </DBButton>
      </div>
    );
  }

  return (
    <DBModalHeader
      title="User Profile"
      className="UserSettingsModal"
      header={<UserHeader user={currentUser} />}
    >
      <ModalTextField
        title="Email"
        description={currentUser?.email}
        renderDivider={<Divider />}
      />
      <ModalTextField
        title="Company"
        description={currentUser?.company.name}
        renderDivider={<Divider />}
      />
      <ModalTextField
        title="Title"
        description={currentUser?.jobTitle}
        renderDivider={<Divider />}
      />
      <ModalTextField
        title="Policy"
        description={
          <Link
            to={{ pathname: currentUser?.company.tradingPolicyUrl }}
            target="_blank"
          >
            Trading Policy
          </Link>
        }
        underline={true}
        renderDivider={<Divider />}
      />

      <ModalTextField
        title="Get support"
        description={
          <Link
            to={{ pathname: 'https://forms.gle/5n4qV2USLsNcSDNRA' }}
            target="_blank"
          >
            Click here to submit bugs or suggested improvements
          </Link>
        }
        underline={true}
        renderDivider={<Divider />}
      />
      <ModalTextField
        title="Give feedback"
        description={`support@opendrawbridge.io`}
        renderDivider={<Divider />}
      />
      {!message ? (
        <div className="buttonContainer">
          <DBButton style={{ width: 240 }} onClick={handleResetPassword}>
            Reset Password
          </DBButton>
        </div>
      ) : (
        <div className="user-profile-message">
          <ModalTextField description={message} />
        </div>
      )}
    </DBModalHeader>
  );
};

export default UserSettingsModal;
