import React from 'react';
import Calendar, {
  DateCallback,
  OnChangeDateRangeCallback,
} from 'react-calendar';
import './DBCalendar.less';
import 'react-calendar/dist/Calendar.less';
import { Form } from 'antd';

interface DBCalendarProps {
  label?: string;
  selectRange?: boolean;
  activeStartDate?: Date;
  value?: Date | Date[] | undefined;
  defaultValue?: Date | Date[];
  allowPartialRange?: boolean;
  onChange?: OnChangeDateRangeCallback;
  onClickDay?: DateCallback;
}

const DBCalendar: React.FC<DBCalendarProps> = ({
  label,
  selectRange,
  activeStartDate,
  value = undefined,
  defaultValue,
  allowPartialRange,
  onChange,
  onClickDay,
}) => {
  const checkIsWeekend = (date: Date) => {
    return date.getDay() === 6 || date.getDay() === 0;
  };

  return (
    <Form.Item label={label}>
      <div className="DBCalendar">
        <Calendar
          value={value}
          tileClassName={({ activeStartDate, date, view }) => {
            return view === 'month' && checkIsWeekend(date) ? 'whiteDay' : null;
          }}
          minDate={new Date()}
          onClickDay={onClickDay}
          activeStartDate={activeStartDate}
          allowPartialRange={allowPartialRange}
          selectRange={selectRange}
          defaultValue={defaultValue}
          next2Label={null}
          prev2Label={null}
          onChange={onChange}
        />
      </div>
    </Form.Item>
  );
};

export default DBCalendar;
