import React, { useMemo, useState } from 'react';
import ReactDataSheet from 'react-datasheet';
import { createCtx } from './createCtx';

export type IValues = number | string | null;

export type ParsedCSVDataRes = string[][];

export interface GridElement extends ReactDataSheet.Cell<GridElement, number> {
  value: IValues;
}

export type CellError = {
  rowId: number;
  colIndex: number;
  type: 'invalidFormat' | 'missingRequiredField';
  description: string;
};

export type KeyedErrorObj = {
  [rowKey: number]: CellError[];
};

export type DisclosureProp = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
};

export type BaseContextProps = {
  /** resourceState */
  grid: GridElement[][];
  canRemoveAddditionalColumns: boolean;
  csvData: ParsedCSVDataRes;
  setCsvData: React.Dispatch<React.SetStateAction<ParsedCSVDataRes>>;
  csvString: string | undefined;
  setCsvString: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export type APIContextProps = {
  setGrid: React.Dispatch<React.SetStateAction<GridElement[][]>>;
  addGroupColumn: () => void;
  removeGroupColumn: () => void;
  errorCheck: (grid?: GridElement[][]) => KeyedErrorObj;
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  //   run check on csv header
  // csvHeaderScheck: (csv: ParsedCSVDataRes) => void;
};

const [useImportAPI, CSVProviderContext] = createCtx<
  BaseContextProps & APIContextProps
>();

/**
 * The purpose of this Provider is to act as a stateful provider/api accessro
 * @param param0
 * @returns
 */
const CSVProvider = ({ children }: any) => {
  const [csvData, setCsvData] = useState<ParsedCSVDataRes>([]);
  const [csvString, setCsvString] = useState<string>();
  const [tabIndex, setTabIndex] = useState(0);

  let emptValue = { value: '' };

  let manyRows = [];
  for (let index = 2; index < 100; index++) {
    manyRows.push([
      { value: index, readOnly: true },
      emptValue,
      emptValue,
      emptValue,
      emptValue,
      emptValue,
      emptValue,
    ]);
  }

  const [grid, setGrid] = useState<GridElement[][]>([
    //   The initial header row
    [
      { readOnly: true, value: '' },
      { readOnly: true, value: 'First Name' },
      { readOnly: true, value: 'Last Name' },
      { readOnly: true, value: 'Email' },
      { readOnly: true, value: 'Role / Job Title' },
      { readOnly: true, value: 'Policy Manager' },
      {
        readOnly: false,
        value: 'All Company',
        valueViewer: ({ value }) => (
          <span className="value-viewer">Group_{value}</span>
        ),
        dataEditor: ({ onChange }) => {
          return (
            <span>
              <span>Group_</span>
              <input
                onChange={(e) => onChange(e.target.value as unknown as number)}
              />
            </span>
          );
        },
      },
    ],
    //   A preliminar example row
    [
      { value: 1, readOnly: true },
      { value: 'John' },
      { value: 'Smith' },
      { value: 'johnsmith@example.com' },
      { value: 'Manager' },
      { value: '1' },
      { value: '1' },
    ],
    // Blank rows for the user to fill
    ...manyRows,
  ]);

  const canRemoveAddditionalColumns = useMemo(() => grid[0].length > 6, [grid]);

  function validateEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const api = {
    addGroupColumn: () => {
      let cloned = [...grid];
      cloned.map((row, i) =>
        i === 0
          ? row.push({
              value: '',
              valueViewer: ({ value }) => (
                <span className="value-viewer">Group_{value}</span>
              ),
              dataEditor: ({ onChange }) => {
                return (
                  <span>
                    <span>Group_</span>
                    <input
                      onChange={(e) =>
                        onChange(e.target.value as unknown as number)
                      }
                    />
                  </span>
                );
              },
            })
          : row.push(emptValue),
      );
      setGrid(cloned);
    },
    removeGroupColumn: () => {
      let cloned = [...grid];
      if (canRemoveAddditionalColumns) cloned.map((row) => row.pop());
      setGrid(cloned);
    },
    errorCheck: (defaultValue = grid) => {
      let errors: KeyedErrorObj = {};

      /**
       * Our CSV Schema supports an optional index column, so we do a check to see if it exists
       * an assign it to `hasNumberIndexCol`
       */
      let secondRowCellvalue = defaultValue?.[1]?.[0]?.value;
      let hasNumberIndexCol = false;
      if (
        // Check to see if the first column is an id column i.e. Integer
        Number.isInteger(parseInt(String(secondRowCellvalue))) === true
      )
        hasNumberIndexCol = true;

      defaultValue.forEach((gridRow, rowIndex) => {
        // Only run error prompt if the row isn't empty
        const isEmptyRow = !gridRow.find(
          (cell, i) => i !== 0 && cell.value !== '',
        );

        const columnNames = gridRow.map((cell) => cell.value);
        let requiredColumnsName = [
          'First Name',
          'Last Name',
          'Email',
          'Role / Job Title',
          'Policy Manager',
        ];

        if (rowIndex === 0) {
          let hasMissingGroupName =
            gridRow.find((cell, i) => i > 6 && cell.value === '') ?? false;

          const columnNameMissing = requiredColumnsName.filter(
            (name) => !columnNames.includes(name),
          );
          if (hasMissingGroupName) {
            errors[rowIndex] = [];
            errors[rowIndex].push({
              rowId: 0,
              colIndex: hasMissingGroupName.colSpan ?? 5,
              description: 'Group is missing a name',
              type: 'missingRequiredField',
            });
          }
          if (columnNameMissing.length > 0) {
            errors[rowIndex] = [];
            errors[rowIndex].push({
              rowId: 0,
              colIndex: 5,
              description: `The required column name/s "${columnNameMissing
                .toString()
                .split(',')
                .join(' - ')}" are missing or misspelled (case sensitive)`,
              type: 'missingRequiredField',
            });
          }
        }

        if (!isEmptyRow && rowIndex !== 0) {
          // This initializes the rowIndex key in the ErrorObj
          errors[rowIndex] = [];

          gridRow.forEach((cell, colIndex) => {
            let val = cell.value?.toString();

            // If an index column exists adjust the oclumn index
            let adjustedColIndex = hasNumberIndexCol ? colIndex : colIndex + 1;

            switch (adjustedColIndex) {
              case 0: // id col
                break;
              case 1: // name col
                if (cell.value?.toString().length === 0)
                  errors[rowIndex].push({
                    rowId: rowIndex,
                    colIndex: adjustedColIndex,
                    description: `Name is required (Row ${rowIndex})`,
                    type: 'missingRequiredField',
                  });
                break;

              case 2: // name col
                if (cell.value?.toString().length === 0)
                  errors[rowIndex].push({
                    rowId: rowIndex,
                    colIndex: adjustedColIndex,
                    description: `Last name is required (Row ${rowIndex})`,
                    type: 'missingRequiredField',
                  });
                break;

              case 3: // email col
                if (cell.value?.toString().length === 0)
                  errors[rowIndex].push({
                    rowId: rowIndex,
                    colIndex: adjustedColIndex,
                    description: `Email is required (Row ${rowIndex})`,
                    type: 'missingRequiredField',
                  });
                if (cell.value && !validateEmail(cell.value.toString()))
                  errors[rowIndex].push({
                    rowId: rowIndex,
                    colIndex: adjustedColIndex,
                    description: `Email must be valid (Row ${rowIndex})`,
                    type: 'invalidFormat',
                  });

                break;

              case 4: // job title col
                if (cell.value?.toString().length === 0)
                  errors[rowIndex].push({
                    rowId: rowIndex,
                    colIndex,
                    description: `Role / job title is required (Row ${rowIndex})`,
                    type: 'invalidFormat',
                  });
                break;

              case 5: // manager col
                if (
                  val !== '0' &&
                  val !== '1' &&
                  val !== 'Yes' &&
                  val !== 'yes' &&
                  val !== 'No' &&
                  val !== 'no' &&
                  val !== ''
                )
                  errors[rowIndex].push({
                    rowId: rowIndex,
                    colIndex,
                    description: `Policy Manager must be either 0 or 1 (Row ${rowIndex})`,
                    type: 'invalidFormat',
                  });

                break;

              case 6: // group col
                if (
                  val !== '0' &&
                  val !== '1' &&
                  val !== 'Yes' &&
                  val !== 'yes' &&
                  val !== 'No' &&
                  val !== 'no' &&
                  val !== ''
                )
                  errors[rowIndex].push({
                    rowId: rowIndex,
                    colIndex,
                    description: `Group columns must be either 0 or 1 (Row ${rowIndex})`,
                    type: 'invalidFormat',
                  });

                break;

              // Default case for all custom rows > the default no.
              default:
                break;
            }
          });
        }
      });
      return errors;
    },
  };

  return (
    <CSVProviderContext
      value={{
        grid,
        csvData,
        setCsvData,
        setGrid,
        canRemoveAddditionalColumns,
        csvString,
        setCsvString,
        tabIndex,
        setTabIndex,
        ...api,
      }}
    >
      {children}
    </CSVProviderContext>
  );
};

export { useImportAPI, CSVProvider };
