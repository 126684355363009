import { approvalGroupsReducer } from './approvalGroupsReducer';
import { tradeRequestReducer } from './tradeRequestReducer';
import { uiReducer } from './uiReducer';
import { groupsReducer } from './groupsReducer';
import { combineReducers } from 'redux';
import { usersReducer } from './usersReducer';
import { restrictionsReducer } from './restrictionsReducer';
import { authReducer } from './authReducer';
import { activeTradesReducer } from './activeTradesReducer';
import { GeneralState } from '../models/State';

type GeneralStateKeys = keyof GeneralState;

type CombinedReducerType = {
  [Key in GeneralStateKeys]?: any;
};

export default combineReducers({
  groups: groupsReducer,
  users: usersReducer,
  tradeRequests: tradeRequestReducer,
  activeTrades: activeTradesReducer,
  restriction: restrictionsReducer,
  ui: uiReducer,
  auth: authReducer,
  approvalGroups: approvalGroupsReducer,
} as CombinedReducerType);
