import { CheckCircleFilled, EditOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Radio, Row, Select, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { formatISO, isBefore } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllTradeRequests,
  updateTradeRequest,
} from '../../actions/tradeRequestActions';
import { closeModal, setModalWidth } from '../../actions/uiActions';
import { api } from '../../api/api';
import {
  TradeRequest,
  TradeRequestReviewHistoryStatusEnum,
  TradeRequestStatusEnum,
} from '../../api/backend-api';
import { useAuth } from '../../context/auth/authContext';
import { GeneralState } from '../../models/State';
import { Status } from '../../models/Status';
import { capitalize, formatDate } from '../../utils/Utils';
import DBButton from '../DBButton/DBButton';
import DBCalendar from '../DBCalendar/DBCalendar';
import DBTableText from '../DBTableText/DBTableText';
import DBTag from '../DBTag/DBTag';
import _ from 'lodash';
import './PendingRequestsModal.less';

interface ApprovalGroupAndUserConditionsMethods {
  tradeHasApprovalGroup: boolean;
  isUserInApprovalGroup: boolean;
  userCanApproveTradeRequest: boolean;
}

const PendingRequestsModal: React.FC = () => {
  const dispatch = useDispatch();
  const { user: me } = useAuth();
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateChanged, setDateChanged] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState<TradeRequestStatusEnum>(
    TradeRequestStatusEnum.Approved,
  );
  const [additionalReviewer, setAdditionalReviewer] = useState<string>();

  const { modalData } = useSelector<GeneralState, any>((state) => state.ui);
  const [tradeRequest, setTradeRequest] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const getTradeRequest = async () => {
    const res = await api.getTradeRequest(modalData.id);
    setTradeRequest(res);
    setLoading(false);
  };

  useEffect(() => {
    dispatch(setModalWidth(1000));
    getTradeRequest();

    return () => {
      dispatch(setModalWidth(undefined));
    };
  }, [dispatch]);

  const [value, setValue] = useState<Date[]>([
    new Date(modalData.startDate),
    new Date(modalData.endDate),
  ]);

  const resetDate = () =>
    setValue([
      new Date(tradeRequest.startDate),
      new Date(tradeRequest.endDate),
    ]);

  const approvalGroupAndUserConditions =
    (): ApprovalGroupAndUserConditionsMethods => {
      let tradeRequestHistory: object[] = [];

      tradeRequest &&
        tradeRequest.reviewHistory.forEach((reviewer: any) =>
          tradeRequestHistory.push({
            ...reviewer,
            email: reviewer.reviewerEmail,
          }),
        );

      return {
        get tradeHasApprovalGroup() {
          if (tradeRequest) {
            const managersEmailRemainingReviewers = (
              tradeRequest as TradeRequest
            ).remainingReviewers!.map((reviewer) => reviewer.email);
            const managersEmailApprovers = (
              tradeRequest as TradeRequest
            ).reviewHistory!.map((reviewer) => reviewer.reviewerEmail);

            return !_.isEmpty([
              ...managersEmailRemainingReviewers,
              ...managersEmailApprovers,
            ]);
          }
          return false;
        },

        get isUserInApprovalGroup() {
          // Check if the current manager exist in the approval group
          return tradeRequest &&
            _.find(
              [...tradeRequest.remainingReviewers, ...tradeRequestHistory],
              {
                email: me?.email,
              },
            )
            ? true
            : false;
        },

        get userCanApproveTradeRequest() {
          const userIsInApprovalGroup = this.isUserInApprovalGroup;

          if (userIsInApprovalGroup) {
            // Check the user is in the review history
            const userIsInReviewHistory = _.find(tradeRequestHistory, {
              email: me?.email,
            });
            // Check the user is an assigned requester
            if (userIsInReviewHistory) {
              return (
                (userIsInReviewHistory as any).status ===
                TradeRequestReviewHistoryStatusEnum.Requested
              );
            }

            if (userIsInApprovalGroup && tradeRequestHistory.length === 0) {
              return true;
            }

            if (userIsInApprovalGroup && !this.tradeHasApprovalGroup) {
              return true;
            }

            return false;
          } else {
            return !this.tradeHasApprovalGroup;
          }
        },
      };
    };

  const handleSubmit = (data: any) => {
    const tradeRequestId = tradeRequest.id;

    if (tradeRequestId) {
      // Should only change dates when status is set to APPROVED
      if (showCalendar && approvalStatus === TradeRequestStatusEnum.Approved) {
        const newTradeRequest: TradeRequest = {
          ...tradeRequest,
          reviewerComment: data.comment,
          reviewerEmail: me?.email,
          status: data.status,
          reviewerName: me?.name,
          startDate: formatISO(value[0], { representation: 'date' }),
          endDate: formatISO(value[1], { representation: 'date' }),
        };
        if (additionalReviewer)
          newTradeRequest['additionalReviewer'] = { email: additionalReviewer };

        dispatch(updateTradeRequest(tradeRequestId, newTradeRequest));
      } else {
        const newTradeRequest = {
          ...tradeRequest,
          reviewerComment: data.comment,
          reviewerEmail: me?.email,
          status: data.status,
          reviewerName: me?.name,
        };
        if (!!additionalReviewer)
          newTradeRequest['additionalReviewer'] = { email: additionalReviewer };

        dispatch(updateTradeRequest(tradeRequestId, newTradeRequest));
      }

      dispatch(closeModal());

      setTimeout(
        () => dispatch(getAllTradeRequests(undefined, 'PENDING')),
        1000,
      );
    }
  };

  const setIntervalValues = (date: Date[]) => {
    const parsedDate = new Date(date[0]);
    if (isBefore(new Date(tradeRequest.startDate), new Date())) {
      return [new Date(tradeRequest.startDate), date[1]];
    }
    return [parsedDate, date[1]];
  };

  const handleChangeDates = (date: any) => {
    const interval = setIntervalValues(date);
    setValue(interval);
    setDateChanged(true);
  };

  let datesDisabled =
    approvalStatus === TradeRequestStatusEnum.Approved ? false : true;

  const alreadySubmitted = useMemo(() => {
    /** If there is onlyOneReviewer i.e. a part of no approval group, then ensure it is not already submitted */
    let onlyOneReviewer = tradeRequest?.remainingReviewers?.length === 0;
    if (onlyOneReviewer) {
      return false;
    } else {
      let alreadySubmitted = !!tradeRequest?.reviewHistory?.find(
        (el: any) =>
          el.reviewerEmail === me?.email &&
          (el.status === TradeRequestReviewHistoryStatusEnum.Approved ||
            el.status === TradeRequestReviewHistoryStatusEnum.Declined),
      );
      return alreadySubmitted;
    }
  }, [tradeRequest, me?.email]);

  if (loading) {
    return <></>;
  }

  return (
    <div className="PendingRequestsModal">
      <div className="PendingRequestModalInfo">
        <Row>
          <div style={{ color: '#1F2933', fontWeight: 400 }}>
            <strong>Trade Request</strong> for {tradeRequest.requesterName}
          </div>

          <Col>
            <span>
              Requested{' '}
              <span
                className={
                  tradeRequest.tradeDirection === 'BUY' ? 'buy' : 'sell'
                }
              >
                {capitalize(tradeRequest.tradeDirection.toLowerCase())}
              </span>{' '}
              trade on{' '}
              {formatDate(tradeRequest.requestedOn, 'h:mm a, d MMM yyyy')}
            </span>
          </Col>
        </Row>
        <div style={{ color: '#1F2933', fontWeight: 400 }}>
          {approvalGroupAndUserConditions().tradeHasApprovalGroup && (
            <strong>Approvers</strong>
          )}
        </div>
        {approvalGroupAndUserConditions().tradeHasApprovalGroup
          ? tradeRequest?.reviewHistory &&
            (tradeRequest?.reviewHistory?.length === 0 ? (
              approvalGroupAndUserConditions().isUserInApprovalGroup ? (
                <div style={{ color: '#7b8794' }}>
                  If you wish to request an additional approval, select one from
                  the dropdown before pressing submit
                </div>
              ) : (
                <span style={{ color: '#7b8794' }}>
                  Managers haven't approved this trade yet.
                </span>
              )
            ) : (
              <>
                {tradeRequest.reviewHistory
                  // Sort Approved approvers first
                  .sort((a: any, b: any) => {
                    if (!a.reviewedOn) return 1;
                    if (a.reviewedOn && b.reviewedOn) {
                      return new Date(a?.reviewedOn).getTime() >
                        new Date(b?.reviewedOn).getTime()
                        ? 1
                        : -1;
                    } else return 1;
                  })
                  .map((reviewer: any) => {
                    let approved =
                      reviewer.status ===
                      TradeRequestReviewHistoryStatusEnum.Approved;
                    return (
                      <Tooltip
                        key={reviewer.reviewerEmail}
                        openClassName={'DBTag dark'}
                        title={approved ? undefined : 'Approval pending'}
                      >
                        <DBTag isInModal={true}
                          theme={approved ? 'approved' : 'dark'}
                          iconLeft={
                            approved ? (
                              <CheckCircleFilled style={{ margin: '0 4px' }} />
                            ) : (
                              <svg
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ height: 12, margin: '0px 4px' }}
                                viewBox="0 0 9 9"
                              >
                                <g clip-path="url(#a)">
                                  <path
                                    d="M4.5 9a.281.281 0 1 1 0-.563 3.937 3.937 0 1 0 0-7.874.281.281 0 1 1 0-.563 4.5 4.5 0 0 1 0 9ZM.299 5.131a.281.281 0 0 1-.282-.258A5.118 5.118 0 0 1 0 4.5c0-.126.008-.25.019-.374a.281.281 0 1 1 .56.047 3.929 3.929 0 0 0-.016.327c0 .11.007.218.016.327a.281.281 0 0 1-.257.303L.3 5.131ZM2.292 1.192a.281.281 0 0 1-.148-.52 4.48 4.48 0 0 1 .665-.34.281.281 0 1 1 .212.52 3.923 3.923 0 0 0-.582.298.281.281 0 0 1-.147.042ZM.737 2.88a.281.281 0 0 1-.25-.408c.112-.222.243-.435.391-.635a.281.281 0 1 1 .453.333c-.13.176-.244.362-.343.556a.281.281 0 0 1-.25.155ZM1.097 7.267a.281.281 0 0 1-.227-.115 4.51 4.51 0 0 1-.39-.638.281.281 0 1 1 .502-.253c.099.195.213.382.342.558a.281.281 0 0 1-.227.448ZM2.91 8.687a.279.279 0 0 1-.107-.02 4.533 4.533 0 0 1-.666-.342.281.281 0 0 1 .296-.478c.186.116.381.216.584.3a.281.281 0 0 1-.107.541Z"
                                    fill="#444"
                                  />
                                  <path
                                    d="M3.457 6.304 2.134 4.98a.281.281 0 0 1 0-.398l.198-.198a.281.281 0 0 1 .398 0l.926.926L6.27 2.696a.281.281 0 0 1 .398 0l.198.199a.281.281 0 0 1 0 .398l-3.01 3.011a.281.281 0 0 1-.399 0Z"
                                    fill="#444"
                                  />
                                  <mask
                                    id="b"
                                    maskUnits="userSpaceOnUse"
                                    x="-6"
                                    y="-2"
                                    width="15"
                                    height="15"
                                  >
                                    <circle
                                      cx="1.969"
                                      cy="5.063"
                                      r="7.031"
                                      fill="#33C136"
                                    />
                                  </mask>
                                </g>
                                <defs>
                                  <clipPath id="a">
                                    <path fill="#fff" d="M0 0h9v9H0z" />
                                  </clipPath>
                                </defs>
                              </svg>
                            )
                          }
                        >
                          {reviewer.reviewerName}{' '}
                          {reviewer.reviewerEmail === me?.email
                            ? ' (You)'
                            : null}
                        </DBTag>
                      </Tooltip>
                    );
                  })}
              </>
            ))
          : null}

        <Row justify="space-between">
          <Col span={8}>
            <DBTableText
              mainText={'Security'}
              capitalize
              subText={`${tradeRequest.assetType.toLowerCase()}`}
            />
          </Col>
          <Col span={8}>
            <DBTableText
              mainText={'Trade Date'}
              capitalize
              subtextClassName={
                showCalendar && !datesDisabled ? 'subtextLineThrough' : ''
              }
              subText={`${formatDate(
                tradeRequest.startDate,
                'd MMM',
              )} - ${formatDate(tradeRequest.endDate, 'd MMM')}`}
              complementaryText={
                showCalendar && value && !datesDisabled
                  ? `${formatDate(value[0].toString(), 'd MMM')} - ${formatDate(
                      value[1].toString(),
                      'd MMM',
                    )}`
                  : ''
              }
            />
          </Col>
          <Col span={8}>
            <DBTableText
              mainText={'Entity Name'}
              subText={`${tradeRequest.entityName}`}
            />
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={8}>
            <DBTableText
              mainText={'Order Type'}
              subText={`${capitalize(tradeRequest.tradeType.toLowerCase())} ${
                tradeRequest.tradeType === 'LIMIT'
                  ? `at $${Number(tradeRequest.assetUnitValue).toFixed(2)}`
                  : ' '
              }`}
            />
          </Col>
          <Col span={8}>
            <DBTableText
              mainText={'Total Value'}
              subText={`${
                tradeRequest.tradeType === 'LIMIT'
                  ? `$${Number(
                      tradeRequest.assetQuantity * tradeRequest.assetUnitValue,
                    ).toFixed(2)}`
                  : ' '
              }`}
            />
          </Col>
          <Col span={8}>
            <DBTableText
              mainText={'Number of Shares'}
              subText={`${Number(tradeRequest.assetQuantity).toLocaleString()}`}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DBTableText
              mainText={'Reason for Trade'}
              subText={`${
                tradeRequest.requesterReason
                  ? tradeRequest.requesterReason
                  : ' '
              }`}
            />
          </Col>
        </Row>
        <Row>
          {!approvalGroupAndUserConditions().tradeHasApprovalGroup ||
          (approvalGroupAndUserConditions().tradeHasApprovalGroup &&
            approvalGroupAndUserConditions().isUserInApprovalGroup) ? (
            <DBButton
              icon={<EditOutlined />}
              onClick={() => {
                setShowCalendar(!showCalendar);
                setDateChanged(false);
                resetDate();
              }}
              disabled={
                datesDisabled ||
                alreadySubmitted ||
                !approvalGroupAndUserConditions().userCanApproveTradeRequest
              }
            >
              {showCalendar ? 'Cancel Changes' : 'Edit trade date'}
            </DBButton>
          ) : (
            <Alert
              message={`This request is pending review from managers authorised to approve trades for a different approval group.`}
              type="error"
            />
          )}
        </Row>
        {showCalendar && !datesDisabled && (
          <DBCalendar
            selectRange={true}
            value={value}
            onChange={handleChangeDates}
          />
        )}
      </div>

      <div
        className="PendingRequestModalDecision"
        style={{
          opacity: alreadySubmitted ? 0.6 : 1,
        }}
      >
        <Row>
          <Col>
            <DBTableText subText="Decision" />
          </Col>
        </Row>

        <Form layout="vertical" onFinish={(data) => handleSubmit(data)}>
          <Form.Item
            name="status"
            initialValue={TradeRequestStatusEnum.Approved}
          >
            <Radio.Group
              disabled={
                alreadySubmitted ||
                (approvalGroupAndUserConditions().tradeHasApprovalGroup &&
                  !approvalGroupAndUserConditions().isUserInApprovalGroup) ||
                !approvalGroupAndUserConditions().userCanApproveTradeRequest
              }
              buttonStyle="solid"
              className="radioGroup"
              value={approvalStatus}
              onChange={(e) => {
                setApprovalStatus(e.target.value);
                e.target.value === TradeRequestStatusEnum.Declined &&
                  setAdditionalReviewer(undefined);
              }}
            >
              <div className="row">
                <Radio.Button value={TradeRequestStatusEnum.Approved}>
                  {dateChanged ? 'Approve with changes' : 'Approve'}
                </Radio.Button>
                <Radio.Button value={Status.Declined}>Decline</Radio.Button>
              </div>
              {approvalGroupAndUserConditions().tradeHasApprovalGroup && (
                <Radio.Button
                  value={TradeRequestStatusEnum.Pending}
                  disabled={tradeRequest?.remainingReviewers?.length === 0}
                >
                  Approve and request additional approver
                </Radio.Button>
              )}
            </Radio.Group>
          </Form.Item>

          {approvalGroupAndUserConditions().tradeHasApprovalGroup && (
            <div
              style={{
                opacity:
                  // This prevents double opacity when alreadySubmitted,
                  // and makes it opaque
                  approvalStatus !== TradeRequestStatusEnum.Pending &&
                  !alreadySubmitted
                    ? 0.6
                    : 1,
              }}
            >
              <div>Request additional approvers</div>
              <div className="flex-row" style={{ flexDirection: 'row' }}>
                <Select
                  className="requestModalApproverSelect"
                  dropdownClassName="DBSelectDropdownRequestScreen"
                  value={additionalReviewer}
                  onSelect={(val) => {
                    setAdditionalReviewer(val as string);
                  }}
                  disabled={approvalStatus !== TradeRequestStatusEnum.Pending}
                  filterOption={false}
                >
                  {tradeRequest?.remainingReviewers &&
                    tradeRequest.remainingReviewers.map((el: any) => {
                      let isSelf = el.email === me?.email;
                      return (
                        <Select.Option
                          key={el.email}
                          value={el.email}
                          className="option"
                          disabled={isSelf}
                        >
                          {el.fullName} {isSelf ? ' (You)' : null}
                        </Select.Option>
                      );
                    })}
                </Select>

                <Button
                  size="small"
                  className="whiteBtn"
                  shape="circle"
                  onClick={() => {
                    setAdditionalReviewer(undefined);
                  }}
                >
                  x
                </Button>
              </div>
            </div>
          )}

          <ul className="questionsList">
            <li>Is this trade no more than 10% of total holdings?</li>
            <li>Are there any market sensitive announcements pending?</li>
          </ul>

          <Form.Item
            name="comment"
            className="commentTextArea"
            label="Comment to Participant (Optional)"
          >
            <TextArea
              disabled={
                alreadySubmitted ||
                (approvalGroupAndUserConditions().tradeHasApprovalGroup &&
                  !approvalGroupAndUserConditions().isUserInApprovalGroup) ||
                !approvalGroupAndUserConditions().userCanApproveTradeRequest
              }
              rows={4}
              className="inputTextArea"
            />
          </Form.Item>
          <DBButton
            variety="create"
            htmlType="submit"
            disabled={
              alreadySubmitted ||
              (approvalGroupAndUserConditions().tradeHasApprovalGroup &&
                !approvalGroupAndUserConditions().isUserInApprovalGroup) ||
              !approvalGroupAndUserConditions().userCanApproveTradeRequest
            }
          >
            {additionalReviewer ? 'Request additional review' : 'Submit'}
          </DBButton>
        </Form>
      </div>
    </div>
  );
};

export default PendingRequestsModal;
