import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Loader } from './components/Loader/Loader';
import { context } from './components/Context/Context';
import DBNotFound from './components/DBNotFound/DBNotFound';
import DashboardWrapper from './components/Dashboard/DashboardWrapper';
import LandingPage from './components/LandingPage/LandingPage';
import DBModal from './components/DBModal/DBModal';
import { useAuth } from './context/auth/authContext';
import EmailVerificationForm from './components/TrialAccountPage/EmailVerificationForm';
import FailedPage from './components/TrialAccountPage/FailedPage';
import DetailsForm from './components/TrialAccountPage/DetailsForm';
import EmailAuthErrorPage from './components/TrialAccountPage/EmailAuthErrorPage';

const AppWrapper: React.FC = () => {
  return (
    <>
      <DBModal />
      <Switch>
        <Route exact path={context.routes}>
          <DashboardWrapper />
        </Route>
        <Route exact path="/signup/:emailVerificationCode/:clientAuthCode">
          <EmailAuthErrorPage />
        </Route>
        <Route exact path="/tryforfree">
          <EmailVerificationForm />
        </Route>
        <Route exact path="/failed">
          <FailedPage />
        </Route>
        <Route exact path="/error">
          <EmailAuthErrorPage />
        </Route>
        <Route>
          <DBNotFound />
        </Route>
      </Switch>
    </>
  );
};

const App: React.FC = () => {
  const { loading, user } = useAuth();

  if (loading) {
    return <Loader />;
  }
  if (user) {
    return <AppWrapper />;
  } else {
    return (
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route exact path="/tryforfree">
          <EmailVerificationForm />
        </Route>
        <Route exact path="/signup/:emailVerificationCode/:clientAuthCode">
          <DetailsForm />
        </Route>
        <Route exact path="/failed">
          <FailedPage />
        </Route>
        <Route exact path="/error">
          <EmailAuthErrorPage />
        </Route>
        <Redirect from="/*" to="/" />
      </Switch>
    );
  }
};

export default App;
