import React, { useState, useEffect } from 'react';
import { Alert, Layout } from 'antd';
import './LandingPage.less';
import { Content } from 'antd/lib/layout/layout';
import DBButton from '../DBButton/DBButton';
import { useAuth } from '../../context/auth/authContext';
import { Link, useHistory } from 'react-router-dom';

const { Sider } = Layout;

const LandingPage: React.FC = () => {
  const { login } = useAuth();
  const history = useHistory();
  const [authError, setAuthError] = useState<boolean | null>();

  useEffect(() => {
    const error = localStorage.getItem('@authentication_error');
    if (Number(error) === 2) setAuthError(true);
  }, []);

  return (
    <Layout className="LandingPage">
      <div className="mobileNavBar">
        <img
          className="mobileImage"
          src={process.env.PUBLIC_URL + '/images/drawbridge_logo_white.svg'}
          alt="Drawbridge Logo"
          onClick={() =>
            window.location.assign('https://www.opendrawbridge.io')
          }
        />
      </div>
      <Sider width={240} className="Sidebar">
        <div
          className="DBLogo"
          onClick={() =>
            window.location.assign('https://www.opendrawbridge.io')
          }
        >
          <img
            className="DBImage"
            src={process.env.PUBLIC_URL + '/images/drawbridge_logo_white.svg'}
            alt="Drawbridge Logo"
          />
        </div>
      </Sider>
      <Layout className="LandingPageLayout">
        {authError && (
          <Alert
            message="Authorization Error"
            description="You do not have the required manager permissions. Please login using the mobile app instead."
            type="error"
            showIcon
            style={{
              maxWidth: 600,
              position: 'absolute',
              top: '1rem',
              right: '1rem',
            }}
          />
        )}
        <Content className="LandingPageContent">
          <h2 className="title">Safeguard your reputation</h2>
          <h3 className="description">
            Drawbridge manages employee and director share trading approvals
            <br className="lineDivisor" /> so you don't become a headline.
          </h3>
          <img
            className="image"
            src={process.env.PUBLIC_URL + '/images/landing-page-image.svg'}
            alt="Drawbridge Empty"
          />

          <div className="LoginContainer" style={{ marginTop: '2rem' }}>
            <div className="boxTitle">Compliance Managers</div>
            <p>For securities trading policy management</p>
            <div className="buttonsContainer">
              <DBButton className="button" size="large" onClick={login}>
                Sign In
              </DBButton>
              <DBButton
                className="button right"
                size="large"
                onClick={() => history.push('/tryforfree')}
              >
                Try for free
              </DBButton>
            </div>
          </div>
          <div className="LoginContainer">
            <div className="boxTitle">Employees & Directors</div>
            <p>For trading requests and approvals</p>
            <div className="logosContainer">
              <Link
                to={{
                  pathname:
                    'https://apps.apple.com/us/app/drawbridge/id1536025353',
                }}
                target="_blank"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <img
                  className="appleLogo"
                  src={process.env.PUBLIC_URL + '/images/apple-store-badge.svg'}
                  alt="apple store"
                />
              </Link>
              <Link
                className="androidLink"
                to={{
                  pathname:
                    'https://play.google.com/store/apps/details?id=com.digitalx.drawbridge',
                }}
                target="_blank"
              >
                <img
                  className="androidLogo"
                  src={process.env.PUBLIC_URL + '/images/google-play-badge.png'}
                  alt="android store"
                />
              </Link>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LandingPage;
