import React, { useEffect } from 'react';
import { Space, Table } from 'antd';
import { formatDate, stringSearch } from '../../utils/Utils';
import {
  changeTab,
  cleanSelectedRows,
  setCurrentRow,
} from '../../actions/uiActions';
import { useDispatch, useSelector } from 'react-redux';
import DBTableText from '../DBTableText/DBTableText';
import { ApprovalGroup } from '../../api/backend-api';
// import DBPopover from '../DBPopover/DBPopover';
import DBTag from '../DBTag/DBTag';
import { getAllApprovalGroups } from '../../actions/approvalGroupActions';
import { ApprovalGroupState, GeneralState, UIState } from '../../models/State';
import './ApprovalGroupsTable.less';
import { createNestedFilter } from '../../utils/dataUtils';
import DBPopover from '../DBPopover/DBPopover';
import { ModalType } from '../../models/Modal';

const ApprovalGroupsTable: React.FC = () => {
  const dispatch = useDispatch();

  const { approvalGroups, loading, filterValues } = useSelector<
    GeneralState,
    ApprovalGroupState
  >((state) => state.approvalGroups);
  const { searchString } = useSelector<GeneralState, UIState>(
    (state) => state.ui,
  );

  useEffect(() => {
    dispatch(changeTab('Approval Groups'));
    dispatch(getAllApprovalGroups());

    return () => {
      dispatch(cleanSelectedRows());
    };
  }, [dispatch]);

  const columns = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      render: (text: string, record: ApprovalGroup) => (
        <DBTableText
          mainText={`${record.name}`}
          subText={`${record.participantCount} Participants`}
        />
      ),
      sorter: {
        compare: (a: any, b: any) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      },
    },
    {
      key: 'createdOn',
      title: 'Created On',
      dataIndex: 'createdOn',
      render: (text: string, record: ApprovalGroup) => (
        <DBTableText
          mainText={`${formatDate(record.createdOn, 'd MMM yyyy')}`}
          subText={`${formatDate(record.createdOn, 'h:mm a')}`}
        />
      ),
      sorter: {
        compare: (a: any, b: any) =>
          Number(new Date(a.createdOn)) - Number(new Date(b.createdOn)),
      },
    },
    {
      key: 'approvalManagers',
      title: 'Managers',
      dataIndex: 'approvalManagers',
      render: (approvalManagers: any) => (
        <Space wrap>
          {approvalManagers.map((manager: any) => (
            <DBTag key={manager.email} theme="dark">
              {manager.fullName}
            </DBTag>
          ))}
        </Space>
      ),
      filters: !loading
        ? createNestedFilter(filterValues['approvalManagers'], 'fullName')
        : [],
      onFilter: (value: any, record: any) => {
        return record.approvalManagers.some(
          (manager: any) => manager.fullName === value,
        );
      },
    },
    {
      key: 'actions',
      title: '',
      dataIndex: 'actions',
      width: 50,
      align: 'center',
      render: () => (
        <DBPopover
          options={[
            {
              name: 'View',
              action: ModalType.ViewApprovalGroupModal,
            },
            {
              name: 'Edit',
              action: ModalType.EditApprovalGroupModal,
            },
            {
              name: 'Delete',
              action: ModalType.DeleteModal,
            },
          ]}
        />
      ),
    },
  ];

  const handleRow = (record: any) => {
    return {
      onClick: () => {
        dispatch(setCurrentRow(record));
      },
    };
  };

  const approvalGroupsToShow = searchString
    ? approvalGroups.filter((group: ApprovalGroup) =>
        stringSearch(searchString, group.name),
      )
    : approvalGroups;

  return (
    <Table
      onRow={handleRow}
      className="ApprovalGroupsTable"
      size="small"
      rowKey="id"
      pagination={{ showSizeChanger: false }}
      loading={loading}
      columns={columns as any}
      dataSource={approvalGroupsToShow}
      getPopupContainer={() => {
        return document.getElementsByClassName(
          'ApprovalGroupsTable',
        )[0] as HTMLElement;
      }}
    />
  );
};

export default ApprovalGroupsTable;
